import { deletePartner } from "../../../services/Api/superadminService";
import { useSuperAdminPartnerStore } from "../../../stores/superadmin/superadminpartner";
import {
  showToastError,
  showToastSuccess,
} from "../../../services/Toasts/toasts";

const DeletePartnerSuperAdmin = ({ idPartner, closeModal }: any) => {
  // Store
  const { sendData, setSendData } = useSuperAdminPartnerStore();

  const handleDeletePartner = (idPartner: number) => {
    deletePartner(idPartner)
      .then(() => {
        closeModal();
        showToastSuccess("Le partenaire a bien été supprimé.");
        setSendData(!sendData);
      })
      .catch((error) => {
        console.log(error);
        closeModal();
        showToastError(
          "Une erreur s'est produite lors de la suppression du partenaire."
        );
      });
  };

  return (
    <>
      <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
        <div className="relative w-auto md:w-4/12 my-6 mx-auto max-w-3xl xl:min-w-[25%]">
          {/*content*/}
          <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
            {/*header*/}
            <div className="flex items-start justify-between p-4 border-b border-solid border-slate-200 rounded-t">
              <h3 className="w-full text-xl 2xl:text-3xl font-semibold text-center text-grey-trio font-K2D">
                Suppression du partenaire
              </h3>
              <button
                className="p-1 ml-auto bg-transparent border-0 text-black opacity-5 float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                onClick={() => closeModal()}
              >
                <span className="bg-transparent text-black opacity-5 h-6 w-6 text-2xl block outline-none focus:outline-none">
                  ×
                </span>
              </button>
            </div>
            {/*body*/}
            <div className="text-center font-light p-5">
              Etes-vous sûr de bien vouloir supprimer ce partenaire ?
            </div>
            {/*footer*/}
            <div className="flex items-center justify-end p-2 border-t border-solid border-slate-200 rounded-b font-BarlowCondensed">
              <button
                className="text-red-300 background-transparent hover:text-red-500 font-bold uppercase px-6 py-2 2xl:text-lg text-base  outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
                type="button"
                onClick={() => closeModal()}
              >
                Annuler
              </button>
              <button
                className="text-white bg-gradient-to-r from-light-lavender via-light-lavender to-dark-lavender hover:bg-gradient-to-br font-medium rounded-lg 2xl:text-lg text-base  px-5 py-2.5 text-center me-2 uppercase  shadow hover:shadow-lg outline-none  ease-linear transition-all duration-150"
                type="button"
                onClick={() => {
                  handleDeletePartner(idPartner);
                }}
              >
                Supprimer
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
    </>
  );
};

export default DeletePartnerSuperAdmin;
