import io from 'socket.io-client';
import { useNotifsStore } from '../../stores/Utils/notifs';
import addNotification from 'react-push-notification';
import logo from '../../assets/logos/logoX.png';
const backendUrl = import.meta.env.VITE_BACKEND_URL;

let socket: any;

export const initSocket = (userId: string) => {
  socket = io(backendUrl as string);
   socket.on('connect', () => {
      socket.emit('joinRoom', userId);
    });

    socket.on("documentUploaded", (data: any) => {
      useNotifsStore.getState().addNotif(data);
      addNotification({
        title: 'Nouveau document !',
        message: data.text,
        theme: 'darkblue',
        native: true,
        silent: false,
        icon: logo,
      });
    });

    socket.on("NewChatMessage", (data: any) => {
      useNotifsStore.getState().addNotif(data);
      addNotification({
        title: 'Nouveau message!',
        message: data.text,
        theme: 'darkblue',
        native: true,
        silent: false,
        icon: logo,
      });
    })

    socket.on('connect_error', (err: any) => {
    });
};

export const leaveRoom = (userId: number) => {
  if (socket && socket.connected) {
    socket.emit('leaveRoom', userId);
    socket.disconnect();
  } else {
    console.log("Le socket n'est pas connecté.");
  }
};