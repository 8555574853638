import { useEffect } from "react";
import { getPartners } from "../../services/Api/superadminService";
import { useSuperAdminPartnerStore } from "../../stores/superadmin/superadminpartner";
import { useSuperAdminCreatePartnerStore } from "../../stores/superadmin/createPartner";
import { useSuperAdminLogsStore } from "../../stores/superadmin/logs";
import useModal from "../../hooks/useModal";
import NavBarSuperAdmin from "../../components/SuperAdmin/NavBarSuperAdmin";
import SuperAdminLogsTable from "../../components/SuperAdmin/SuperAdminLogsTable";
import SuperAdminPartnerTable from "../../components/SuperAdmin/SuperAdminPartnerTable";
import { ToastContainer } from "react-toastify";
import Header from "../../components/Layout/Header";

const SuperAdminHome = () => {
  // Store
  const { setPartners, pathVisible, setModePartner, sendData } =
    useSuperAdminPartnerStore();
  const { openDetails, setOpenDetails, setDisplayDiv } =
    useSuperAdminCreatePartnerStore();

  // Hook
  const { modalContent, isModalOpen, openModal, closeModal } = useModal();
  const { handleDownloadLogs } = useSuperAdminLogsStore();

  useEffect(() => {
    getPartners()
      .then((res) => {
        const sortedData = res?.data.sort((a: any, b: any) => a.id - b.id);
        setPartners(sortedData);
      })
      .catch((err) => console.log(err));
  }, [sendData]);

  return (
    <div className="w-screen h-screen bg-white flex flex-col justify-start items-start overflow-hidden ">
      {isModalOpen ? modalContent : null}
      <ToastContainer position="top-center" />
      <Header />
      
      <div className="w-full h-full flex justify-start items-start">
        <NavBarSuperAdmin />
        {pathVisible === "partners" ? (
          <div className="flex flex-col justify-center items-center h-screen w-full">
            <div className="flex items-center justify-between w-11/12 font-K2D">
              {!openDetails ? (
                <button
                  className="hidden xl:w-44 xl:h-10 xl:flex xl:mb-2 items-center justify-center rounded-md border-[1px] border-yellow-trio p-2 bg-yellow-trio font-K2D text-md shadow-xl"
                  onClick={() => {
                    setDisplayDiv(true);
                    setOpenDetails(true);
                    setModePartner("create");
                  }}
                >
                  Créer un partenaire
                </button>
              ) : null}
            </div>
            <div className="flex h-[90%] md:h-[75%] w-11/12 mb-20">
              <SuperAdminPartnerTable
                closeModal={closeModal}
                openModal={openModal}
              />
            </div>
          </div>
        ) : pathVisible === "logs" ? (
          <div className="flex flex-col justify-start items-center w-11/12 h-full">
            <div className="flex items-center justify-between w-11/12 font-K2D mb-10">
              <h1 className="text-center text-4xl text-dark-lavender font-extralight">
                Logs :
              </h1>
              <button
              className="hidden xl:w-44 xl:h-10 xl:flex mt-5 xl:mb-2 items-center justify-center rounded-md border-[1px] border-yellow-trio p-2 bg-yellow-trio font-K2D text-md shadow-xl"
              onClick={() => handleDownloadLogs()}
              >
                Télécharger
              </button>
            </div>
            <div className="flex h-[90%] md:h-[75%] w-11/12 mb-20">
              <SuperAdminLogsTable />
            </div>
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default SuperAdminHome;
