import React from "react";
import useModal from "../../../hooks/useModal";
import Trash from "../../../assets/icons/trash.svg";
import Send from "../../../assets/icons/send.svg";
import { sendBackMail } from "../../../services/Api/userService";
import { useServiceStore } from "../../../stores/admin/service";
import { usePartnerStore } from "../../../stores/admin/users";
import { showToastSuccess } from "../../../services/Toasts/toasts";
import { dateFormat } from "../../../services/Utils/functionService";

const CustomerAdminTable = () => {
  // Store
  const { usersCustomer } = usePartnerStore();

  // Hook
  const { modalContent, isModalOpen, openModal, closeModal } = useModal();

  // Function to send back mail to user
  const handleSendBack = (idUser: number) => {
    sendBackMail(idUser)
      .then(() => showToastSuccess("L'email a bien été envoyé à l'utilisateur"))
      .catch((err) => console.log(err));
  };

  return (
    <div className="h-full w-full rounded-md flex">
      {isModalOpen ? modalContent : null}
      <div className="flex flex-col w-full h-full font-BarlowCondensed ">
        <div className="w-full m-auto py-2 grid grid-cols-8 grid-rows-1 text-xl border-b-[1px] border-zinc-200 text-grey-trio bg-white">
          <div className="col-start-1 col-end-3 font-bold">Nom</div>
          <div className="col-start-3 col-end-4 font-bold">Prénom</div>
          <div className="col-start-4 col-end-6 font-bold">Email</div>
          <div className="col-start-6 col-end-7 font-bold">Titre</div>
          <div className="col-start-7 col-end-8 font-bold">
            Dernière connexion
          </div>
          <div className="col-start-8 col-end-9 font-bold"></div>
        </div>
        <div className="w-full h-full overflow-y-scroll bg-white">
          {usersCustomer?.map((user: any) => (
            <div
              key={user?.id}
              className={
                "w-full hover:bg-light-lavender transition-all duration-150 hover:bg-opacity-20 m-auto grid grid-cols-8 2xl:text-xl text-md font-light text-lg h-auto py-2"
              }
            >
              <div className="col-start-1 col-end-3 flex items-center">
                <p className="">{user?.lastName}</p>
              </div>
              <div className="col-start-3 col-end-4 flex items-center">
                <p className="pl-2">{user?.firstName}</p>
              </div>
              <div className="col-start-4 col-end-6 flex items-center">
                <p className="pl-2">{user?.eMail}</p>
              </div>
              <div className="col-start-6 col-end-7 flex items-center pl-1">
                <p className="pl-2">{user?.titre}</p>
              </div>
              <div className="col-start-7 col-end-8 flex items-center pl-3">
                {user?.lastConnection === null
                  ? "Jamais connecté"
                  : dateFormat(user?.lastConnection)}
              </div>
              <div className="col-start-8 col-end-9 flex items-center pt-1">
                  <button
                    className="mr-14"
                    onClick={() => handleSendBack(user?.id)}
                  >
                    <img src={Send} alt="Renvoi" className="block" />
                  </button>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default React.memo(CustomerAdminTable);
