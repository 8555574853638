import React from "react";
import useModal from "../../../hooks/useModal";
import Trash from "../../../assets/icons/trash.svg";
import Archive from "../../../assets/icons/archive.svg";
import DeleteClientFile from "../Modales/DeleteClientFile";
import ArchiveDeleteFile from "../Modales/ArchiveDeleteFile";
import { SelectorResponsable } from "../Services/SelectorResponsable";
import { useClientFileStore } from "../../../stores/admin/clientFile";

const ClientFilesAdminTable = () => {
  // Store
  const {
    clientFiles,
    fullName,
    setFullName,
    editingClientFileId,
    setEditingClientFileId,
    setUpdatedClientFile,
    setConvertResponsable,
  } = useClientFileStore();

  // Hook
  const { modalContent, isModalOpen, openModal, closeModal } = useModal();

  return (
    <div className="h-full w-full flex">
      {isModalOpen ? modalContent : null}
      <div className="flex flex-col w-full h-full font-BarlowCondensed ">
        <div className="w-full m-auto py-2 grid grid-cols-7 grid-rows-1 rounded-t-md text-xl border-b-[1px] border-zinc-200 text-grey-trio">
          <div className="col-start-1 col-end-3 font-bold">Nom du dossier</div>
          <div className="col-start-3 col-end-5 font-bold">Responsable</div>
          <div className="col-start-5 col-end-7 font-bold">
            Nombre de missions
          </div>
        </div>
        <div className="w-full h-full overflow-y-scroll bg-white mb-5font-bold">
          {clientFiles?.map((clientfile: any) => (
            <div
              key={clientfile?.id}
              className={
                editingClientFileId === clientfile?.id
                  ? "w-full bg-gray-100 m-auto grid grid-cols-7 h-10  2xl:text-xl text-md font-light justify-start items-center"
                  : clientfile?.closed === 1
                  ? "w-full bg-gray-100  m-auto grid grid-cols-7 h-10 italic text-gray-400 2xl:text-xl text-md font-light justify-start items-center"
                  : "w-full hover:bg-light-lavender hover:bg-opacity-20 transition-all duration-150 m-auto grid grid-cols-7 h-10  2xl:text-xl text-md font-light justify-start items-center"
              }
            >
              <div
                className="col-start-1 col-end-3"
                onClick={() => setEditingClientFileId(clientfile?.id)}
              >
                {editingClientFileId === clientfile?.id ? (
                  <input
                    type="text"
                    className="w-11/12 pt-1"
                    defaultValue={clientfile?.name}
                    onChange={(e) =>
                      setUpdatedClientFile({
                        ...clientfile,
                        name: e.target.value,
                      })
                    }
                  />
                ) : (
                  clientfile?.name
                )}
              </div>
              <div
                className="col-start-3 col-end-5 ml-1"
                onClick={() => {
                  setEditingClientFileId(clientfile?.id);
                  if (clientfile?.User?.fullName) {
                    setFullName(clientfile?.User?.fullName);
                  }
                }}
              >
                {editingClientFileId === clientfile?.id ? (
                  clientfile?.User?.id ? (
                    <SelectorResponsable
                      id
                      setConvertResponsable={setConvertResponsable}
                      fullName={fullName}
                    />
                  ) : (
                    clientfile?.User?.fullName
                  )
                ) : (
                  clientfile?.User?.fullName
                )}
              </div>
              <div className="col-start-5 col-end-7 ml-5 ">
                {clientfile?.missionCount}
              </div>
              <div className="col-start-7 col-end-8 pt-1">
                <div className="grid grid-cols-2">
                  <button
                    className="col-start-1 col-end-2 "
                    onClick={() =>
                      openModal(
                        <ArchiveDeleteFile
                          closed={clientfile?.closed}
                          id={clientfile?.id}
                          closeModal={closeModal}
                        />
                      )
                    }
                  >
                    <img src={Archive} alt="trash" className="pt-1" />
                  </button>
                  <button
                    className="col-start-2 col-end-3"
                    onClick={() =>
                      openModal(
                        <DeleteClientFile
                          id={clientfile?.id}
                          closeModal={closeModal}
                        />
                      )
                    }
                  >
                    <img src={Trash} alt="trash" />
                  </button>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default React.memo(ClientFilesAdminTable);
