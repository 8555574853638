import SearchBar from "../../components/Admin/Services/SearchBar";
import NavBarAdmin from "../../components/Admin/Layout/NavBarAdmin";
import MissionsAdminTable from "../../components/Admin/Table/MissionsAdminTable";
import { useEffect } from "react";
import { getAllMissions } from "../../services/Api/adminService";
import { useServiceStore } from "../../stores/admin/service";
import { useMissionStore } from "../../stores/admin/mission";
import { useParams } from "react-router-dom";
import Header from "../../components/Layout/Header";
import Footer from "../../components/Layout/Footer";

const AdminClientFile = () => {
  // Store
  const { update, searchTerm } = useServiceStore();
  const { missions, setMissions } = useMissionStore();
  const { idPartner } = useParams();

  useEffect(() => {
    if (searchTerm !== "") {
      const filteredRequests = missions.filter(
        (item: any) =>
          item?.name?.toLowerCase().includes(searchTerm.toLowerCase()) ||
          item?.CategoryMission?.name
            .toLowerCase()
            .includes(searchTerm.toLowerCase())
      );
      setMissions(filteredRequests);
    } else {
      getAllMissions(Number(idPartner))
        .then((data) => setMissions(data))
        .catch((error) => console.log(error));
    }
  }, [searchTerm, update]);

  return (
    <div className="w-screen h-screen bg-white flex flex-col justify-start items-start overflow-hidden ">
      <Header />
      <div className="w-full flex justify-start items-start">
        <NavBarAdmin />

        <div className="flex flex-col justify-start items-center w-11/12 h-screen m-auto">
          <div className="w-11/12 flex justify-start sm:justify-between items-center xl:my-3">
            <h2 className="text-nxm-darkGray font-light 2xl:text-3xl text-xl font-K2D 2xl:py-5 py-2 ">
              Liste des missions :
            </h2>
          </div>

          <div className=" w-11/12 flex justify-between items-center">
            <div className="my-5 w-auto">
              <SearchBar />
            </div>
          </div>
          <div className="flex h-[90%] md:h-[75%] w-11/12 mb-20">
            <MissionsAdminTable />
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default AdminClientFile;
