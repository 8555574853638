import { create } from "zustand";
import { IUserManagerMission } from "../../interfaces/user";
import { IServiceMission } from "../../interfaces/mission";
import { ICategoryRequest } from "../../interfaces/requestsInterface";
import { StateList } from "../../interfaces/state";

interface UtilsState {
    //select manager in the mission
    managers: IUserManagerMission[];
    setManagers: (managers: IUserManagerMission[]) => void;

    selectedManager: number;
    setSelectedManager: (selectedManager: number) => void;

    //select service in the mission
    services: IServiceMission[];
    setServices: (services: IServiceMission[]) => void;

    selectedService: number;
    setSelectedService: (selectedService: number) => void;

    //select year in the mission
    years: string[];

    selectedYear: string;
    setSelectedYear: (selectedYear: string) => void;

    //select start date in the mission
    startDate: Date | null;
    setStartDate: (startDate: Date | null) => void;

    categories: ICategoryRequest[];
    setCategories: (categories: ICategoryRequest[]) => void;

    selectedCategory: number;
    setSelectedCategory: (selectedCategory: number) => void;

    states: StateList[];
    setStates: (states: StateList[]) => void;

    selectedState: number;
    setSelectedState: (selectedState: number) => void;

    isRenewable: boolean;
    setIsRenewable: (isRenewable: boolean) => void;

    periods: any[];
    setPeriods: (periods: any[]) => void;

    selectedPeriod: number;
    setSelectedPeriod: (selectedPeriod: number) => void;

    startMonth: any;
    setStartMonth: (startMonth: any) => void;

    resetSelects: () => void;
}

export const useUtilsStore = create<UtilsState>((set) => ({
    // select manager in the mission
    managers: [],
    setManagers: (managers) => set({ managers: managers }),

    selectedManager: 0,
    setSelectedManager: (selectedManager) => set({ selectedManager: selectedManager }),

    // select service in the mission
    services: [],
    setServices: (services) => set({ services: services }),

    selectedService: 0,
    setSelectedService: (selectedService) => set({ selectedService: selectedService }),

    // select year in the mission
    years: [
        '2022',
        '2022/2023',
        '2023',
        '2023/2024',
        '2024',
        '2024/2025',
        '2025',
        '2025/2026',
        '2026',
        '2026/2027',
        '2027',
        '2027/2028',
        '2028',
        '2028/2029',
        '2029',
        '2029/2030',
        '2030',
    ],

    selectedYear: "",
    setSelectedYear: (selectedYear) => set({ selectedYear: selectedYear }),

    startDate: null,
    setStartDate: (startDate) => set({ startDate: startDate }),

    categories: [],
    setCategories: (categories) => set({ categories: categories }),

    selectedCategory: 0,
    setSelectedCategory: (selectedCategory) => set({ selectedCategory: selectedCategory }),

    states: [],
    setStates: (states) => set({ states: states }),

    selectedState: 0,
    setSelectedState: (selectedState) => set({ selectedState: selectedState }),

    isRenewable: false,
    setIsRenewable: (isRenewable) => set({ isRenewable: isRenewable }),

    periods: [],
    setPeriods: (periods) => set({ periods: periods }),

    selectedPeriod: 0,
    setSelectedPeriod: (selectedPeriod) => set({ selectedPeriod: selectedPeriod }),

    startMonth: null,
    setStartMonth: (startMonth) => set({ startMonth: startMonth }),

    resetSelects: () => set({
        selectedManager: 0,
        selectedService: 0,
        selectedYear: "",
        startDate: null,
        selectedCategory: 0,
        selectedState: 0,
        isRenewable: false,
        selectedPeriod: 0,
        startMonth: null,
    }),
}));