import { useNavigate } from "react-router-dom";
import Logo from "../../assets/logos/logoX.png";

const CGU = () => {
  const navigate = useNavigate();
  return (
    <div className="w-screen flex flex-col items-center justify-center">
      <img
        src={Logo}
        alt="logo Nexum"
        className="w-24 h-36 py-5 cursor-pointer"
        onClick={() => navigate(-1)}
      />
      <div className="w-screen bg-light-lavender h-32 flex flex-col items-center justify-center">
        <h1 className="text-lg text-center font-extralight">
          <span className="font-bold text-3xl">
            Conditions générales d'utilisation de Nexum
          </span>{" "}
          <br /> en date du 23/02/2023
        </h1>
      </div>

      <div className="w-10/12 flex flex-col items-center justify-start">
        <h3 className="w-10/12 text-xl pt-5 text-dark-lavender font-bold">
          Article 1. OBJET DES CONDITIONS GENERALES D’UTILISATION
        </h3>

        <p className="w-10/12 py-2 text-justify">
          Les conditions générales d’utilisation ont pour objet de préciser les
          modalités d’utilisation et de navigation au sein du site et de la
          plateforme sécurisée, dénommés respectivement « Nexum ».
          <br></br> Les présentes conditions générales d’utilisation
          s’appliquent à tout utilisateur accédant à Nexum. <br></br>Chaque
          utilisateur doit accepter, sans réserve, les conditions générales
          d’utilisation lorsqu’il accède pour la première fois à Nexum.{" "}
          <br></br>Autrement dit, l’accès au site par l’utilisateur signifie
          acceptation expresse et entière des conditions générales
          d’utilisation. <br></br>Par cette acceptation, l’utilisateur confirme
          avoir lu et compris l’intégralité des conditions générales
          d’utilisation avant toute utilisation des services proposés par Nexum
          et s’engage à les respecter.
        </p>

        <h3 className="w-10/12 text-xl pt-5 text-dark-lavender font-bold">
          Article 2. MENTIONS LEGALES
        </h3>

        <p className="w-10/12 py-2 text-justify">
          L’édition du Site est assurée par le service informatique de TRIONODE
          SARL.
        </p>

        <h3 className="w-10/12 text-xl text-dark-lavender font-bold pt-8">
          Article 3. DEFINITIONS
        </h3>

        <p className="w-10/12 py-2 text-justify">
          Les mots et expressions ci-après commençant par une lettre majuscule,
          au singulier ou au pluriel, sont employés dans les conditions
          générales d’utilisation avec la signification suivante :<br></br>
          <br></br>« Service IT » : le département IT en charge de la gestion de
          la plateforme Nexum chez TRIONODE. <br></br>
          <br></br>« Compte professionnel » : compte créé par l’utilisateur pour
          accéder à Nexum. <br></br>
          <br></br>« Conditions générales d’utilisations » ou « CGU » ou « les
          CGU ». <br></br>
          <br></br>« Donnée(s) à caractère personnel » / « Donnée(s)
          personnelle(s) » / « Information(s) nominative(s) » : toute
          information se rapportant à une personne physique identifiée ou
          identifiable (ci-après la « Personne concernée »). Est réputée être
          une « personne physique identifiable » toute personne physique qui
          peut être identifiée, directement ou indirectement, notamment par
          référence à un identifiant, tel qu’un nom, un numéro d’identification,
          des données de localisation, un identifiant en ligne, ou à un ou
          plusieurs éléments spécifiques propres à son identité physique,
          physiologique, génétique, psychique, économique, culturelle ou
          sociale. <br></br>
          <br></br>« Société » : désignant une personne morale. <br></br>
          <br></br>« Identifiant » : désignant un identifiant unique définit par
          l’utilisateur lors de la création de son compte professionnel. Il
          permet à un utilisateur de s'identifier et d'accéder à Nexum.{" "}
          <br></br>
          <br></br>« Nexum » : désignant la solution de plateforme d’échange
          créée et gérée par TRIONODE SARL. <br></br>
          <br></br>« Données téléchargées » : l’ensemble des données, documents,
          fichiers, commentaires, informations ou matériels saisis sur Nexum ou
          mis à disposition d’une autre manière par TRIONODE SARL. <br></br>
          <br></br>« Mission » : le dossier d’échange de documents créés sur la
          plateforme dans le cadre d’une des missions effectuées par TRIONODE
          SARL. <br></br>
          <br></br>« Requête » : désignant la demande de documents ou fichiers
          formulée par TRIONODE SARL dans le cadre d’une de ses missions. « Site
          » : « nexum.trionode.mc», <br></br>
          <br></br>« Utilisateur » : toute personne physique faisant usage de la
          plateforme Nexum.
        </p>

        <h3 className="w-10/12 text-xl pt-5 text-dark-lavender font-bold">
          Article 4. SIEGE SOCIAL
        </h3>

        <p className="w-10/12 py-2 text-justify">
          Le siège social de TRIONODE est situé : <br></br>
          <br></br>L'Herakleia, 2, boulevard du Jardin Exotique à Monaco
          <br></br>98000 - MONACO
          <br></br>Tél. 06 08 11 76 46
          <br></br>Le Site est hébergé par : <br></br>
          <br></br>TRIONODE SARL
        </p>

        <h3 className="w-10/12 text-xl pt-5 text-dark-lavender font-bold">
          Article 5. OBJET DU SITE
        </h3>

        <p className="w-10/12 py-2 text-justify">
          TRIONODE SARL a pour objet de proposer une plateforme sécurisée
          d’échange de documents, dénommée « Nexum ». <br></br>
          <br></br>Depuis le Site, l’Utilisateur peut notamment : <br></br>Se
          connecter en utilisant son Identifiant et son mot de passe ; <br></br>
          Accéder aux différentes Missions qui lui sont attribuées ; <br></br>
          Mettre en ligne des documents ou fichiers sur la plateforme ainsi que
          télécharger ces mêmes documents depuis Nexum ;<br></br> Différencier
          les Missions en cours et les requêtes effectuées par Missions ;{" "}
          <br></br>Accéder à Nexum depuis les supports numériques suivant : PC,
          tablette et mobile.
        </p>

        <h3 className="w-10/12 text-xl pt-5 text-dark-lavender font-bold">
          Article 6. ACCES AU SITE
        </h3>

        <p className="w-10/12 py-2 text-justify">
          L’Utilisateur aura besoin d’un Identifiant et d’un mot de passe pour
          accéder à Nexum ainsi que d’un code reçu par mail (expirant tous les
          14 jours à la demande de l’utilisateur). <br></br>L’Utilisateur et sa
          Société sont seuls responsables de tout activité par le biais de leur
          Compte professionnel, à moins que l’Utilisateur ou la Société demande
          sa désactivation. <br></br>À tout moment, l’Utilisateur ou sa Société
          peuvent désactiver le compte d’Utilisateur pour une raison quelconque
          ou sans raison. Pour ce faire, l’Utilisateur ou sa Société doivent
          envoyer une notification par email au service IT. L’Utilisateur
          s’engage à avertir immédiatement le service IT de toute utilisation
          non autorisée de ces informations à l’adresse email suivante :
          support@trionode.mc. <br></br>TRIONODE SARL ne saurait être tenue
          pour responsable des dommages éventuellement causés par l’utilisation
          de l’Identifiant et du mot de passe par un tiers non autorisé.{" "}
          <br></br>
          Lors de sa première utilisation, l’Utilisateur est invité à accéder à
          son Compte professionnel existant via l’email d’information venant du
          Site. Pour créer un Compte professionnel, il est nécessaire de
          renseigner : adresse email, nom(s), prénom(s). Il doit appliquer à ces
          informations toutes les précautions et mesures de sécurité adéquates
          pour notamment en préserver l’intégrité et la confidentialité.{" "}
          <br></br>L’Utilisateur s’engage à saisir lesdites données sous son
          entière responsabilité, contrôle et direction et s’engage à
          communiquer des informations complètes, exactes et actualisées, et à
          ne pas usurper l’identité d’un tiers. L’Utilisateur doit conserver de
          manière sécurisée son Identifiant et son mot de passe qui lui seront
          utiles pour tout accès à son Compte professionnel. <br></br>
          La connexion au Compte professionnel s’opère en remplissant
          l’Identifiant et le mot de passe personnels de l’Utilisateur suivi par
          le code reçu par mail. En cas de succès, l’Utilisateur accède à son
          Compte professionnel et aux différentes Missions et requêtes qui lui
          sont affectées. <br></br>Si l’Utilisateur oublie son mot de passe, ce
          dernier doit cliquer sur le lien « Mot de passe oublié ? » dans
          l’interface de connexion. La procédure de récupération lui est alors
          décrite. <br></br>Il est rappelé qu’un mot de passe est strictement
          personnel et confidentiel et ne doit jamais être communiqué. Le mot de
          passe personnel ne doit pas utiliser tout ou partie de l’Identifiant,
          du prénom ou du nom de famille de l’Utilisateur. L’Utilisateur
          s’engage à modifier sans délai son mot de passe dès lors qu’il
          suspecte une utilisation frauduleuse de son Compte professionnel et à
          ne jamais stocker en clair son mot de passe.<br></br> L’Utilisateur
          devra fournir une adresse e-mail valide lors de la création du Compte
          professionnel. Cette adresse est nécessaire et utilisée pour la
          confirmation des opérations réalisées par l’Utilisateur. <br></br>Dans
          le cas où un Utilisateur souhaiterait faire part au service IT d’une
          erreur ou d’une correction, il est prié de le faire à l’adresse email
          suivante : support@trionode.mc.
        </p>

        <h3 className="w-10/12 text-xl pt-5 text-dark-lavender font-bold">
          Article 7. CONSERVATION DES DONNEES DE CONNEXION
        </h3>

        <p className="w-10/12 py-2 text-justify">
          Compte professionnel <br></br>Un Compte professionnel qui n’est pas
          utilisé pendant plus de trois (3) ans entre dans un processus
          automatique de désinscription. Un courrier électronique prévient
          l’Utilisateur de sa désinscription à venir. L’Utilisateur peut
          également se désinscrire à tout moment en envoyant un courriel à
          l’adresse email suivante : v.otten@trionode.mc.<br></br>
          <br></br> La désinscription entraine l’effacement définitif de toutes
          les données de connexion de l’Utilisateur dans un délai de dix (10)
          jours ouvrables.<br></br>
          <br></br> Données de connexion <br></br>Certaines données de connexion
          sont conservées aux fins de protection contre les intrusions et
          d’éventuelles poursuites judiciaires le cas échéant. Ces données sont
          conservées pendant douze (12) mois après l’archivage d’une Mission.{" "}
          <br></br>
          <br></br>Contenu mis en ligne <br></br>Les documents et autres
          fichiers apportés sur la plateforme sont conservés aux fins de
          permettre un accès rétroactif aux données dans le cadre d’une
          inspection sur les travaux effectués. Ces données sont conservées
          pendant douze (12) mois après l’archivage d’une Mission.
        </p>

        <h3 className="w-10/12 text-xl pt-5 text-dark-lavender font-bold">
          Article 8. PROTECTION DES DONNEES A CARACTERE PERSONNEL
        </h3>

        <p className="w-10/12 py-2 text-justify">
          Conformément aux dispositions de la loi n° 1.165 du 23 décembre 1993
          relative à la protection des informations nominatives, modifiée,
          TRIONODE SARL en sa qualité de responsable du traitement, s’engage à
          préserver la confidentialité des Données à caractère personnel
          fournies par les Utilisateurs dans le cadre de la création de leur
          Compte professionnel. <br></br>
          <br></br>Ces informations sont exploitées dans le cadre de
          l’identification des Utilisateurs et pour des fins de cybersécurité.
          <br></br>
          <br></br>Le traitement des Données à caractère personnel s’inscrit
          dans les Missions de TRIONODE SARL. Il est justifié par le
          consentement de l’Utilisateur et/ou par l'exécution d'un contrat avec
          l’Utilisateur.
          <br></br>
          <br></br>Les Données à caractère personnel ainsi collectées sont
          conservées pendant la durée de l’existence du compte de l'Utilisateur
          et dans la limite de douze (12) mois après l’archivage d’une Mission.{" "}
          <br></br>
          <br></br>En vertu de l’article 14 de la loi n°1.165 du 23 décembre
          1993 modifiée, relative à la protection des informations nominatives,
          l’Utilisateur dispose d’un droit d’accès, de rectification et
          d’opposition concernant ses Données à caractère personnel.
          <br></br>
          <br></br>L’Utilisateur exerce ce droit auprès de la TRIONODE SARL à
          l’adresse suivante :<br></br>
          <br></br>L'Herakleia, 2 Boulevard du Jardin Exotique 98000 - MONACO
        </p>

        <h3 className="w-10/12 text-xl pt-5 text-dark-lavender font-bold">
          Article 9. CONFIDENTIALITE ET DIVULGATION DES INFORMATIONS DES
          UTILISATEURS{" "}
        </h3>

        <p className="w-10/12 py-2 text-justify">
          TRIONODE SARL se réserve le droit de divulguer les informations des
          Utilisateurs et données afférent à leur Compte professionnel à
          d’autres personnes, si elle y est contrainte afin de se conformer à la
          loi, aux exigences règlementaires, aux normes professionnelles ou pour
          prévenir tout préjudice à l’Utilisateur concerné.<br></br>
          <br></br> Dans ces hypothèses, l’Utilisateur sera informé sans délai
          de la nécessité de divulgation de ses Données à caractère personnel.
        </p>

        <h3 className="w-10/12 text-xl pt-5 text-dark-lavender font-bold">
          Article 10. TELECHARGEMENT DES DONNEES SUR Nexum
        </h3>

        <p className="w-10/12 py-2 text-justify">
          Obligations relatives aux données téléchargées sur Nexum <br></br>
          <br></br>
          Nexum permet aux Utilisateurs de télécharger les données importées
          dans leur espace sur la plateforme.<br></br>
          <br></br>
          L’Utilisateur et la Société sont seuls responsables des Données
          téléchargées, et sans limitation de ce qui précède, ils s’assurent de
          ce qui suit : <br></br>
          Autorisation appropriée de la Société à fournir les Données
          téléchargées, Absence de contenu faux, offensant, indécent,
          diffamatoire, calomnieux, harcelant, menaçant ou nuisible au sein des
          Données téléchargées, Absence de violation de toute loi,
          règlementation, norme professionnelle, vie privée d’un tiers, droit
          d’auteur, marque commerciale ou droit de propriété intellectuelle d’un
          tiers par la fourniture des Données téléchargées. <br></br>
          <br></br>
          Accès aux Données téléchargées sur Nexum <br></br>
          L’Utilisateur et la Société conviennent de ce qui suit s’agissant de
          l’accès et l’utilisation des Données téléchargées : <br></br>
          Les Données téléchargées sont disponibles pour TRIONODE ainsi que ses
          salariés, Les Données téléchargées sont disponibles pour le personnel
          du réseau, ainsi que pour les sous-traitants de TRIONODE (qui peuvent
          inclurent de manière non exhaustive des tiers fournissant des services
          tels que l’administration, le soutien, la gestion ou l’hébergement de
          Nexum) qui peuvent y accéder pour des périodes limitées dans des
          circonstances telles que l’administration du site, le dépannage, la
          maintenance du système, les urgences, tout soutien technique, ou si la
          loi ou toute procédure l’exige. <br></br>
          <br></br>
          Il est par ailleurs précisé que dans cette hypothèse, les personnes
          ayant accès aux Données téléchargées seront soumise à une obligation
          de confidentialité. TRIONODE SARL se réserve d’examiner et supprimer
          le cas échéant de Nexum, une partie ou l’ensemble des Données
          téléchargées s’il est estimé que ces dernières ont été fournies en
          violation des Conditions générales d’utilisation ou d’une Mission, ou
          si elles sont périmées ou non pertinentes pour les Utilisateurs.
          Toutefois et sauf indication contraire, TRIONODE SARL n’est pas tenue
          de vérifier l’exhaustivité, l’authenticité ou l’actualité des Données
          téléchargées, ni d’en examiner la nature ou le contenu. <br></br>
          Autrement dit, les Données téléchargées ne sont utilisées par TRIONODE
          SARL que conformément aux conditions de la lettre de mission
          applicable.
        </p>

        <h3 className="w-10/12 text-xl pt-5 text-dark-lavender font-bold">
          Article 11. ACCESSIBILITE ET DISPONIBILITE
        </h3>

        <p className="w-10/12 py-2 text-justify">
          Nexum ne garantit aucun stockage des Données téléchargées. En d’autres
          termes, Nexum n’est pas tenue de stocker, converser ou fournir à
          l’Utilisateur ou la Société des Données téléchargées. <br></br>
          Dès lors, il est conseillé à l’Utilisateur et à la Société de
          conserver des copies de toutes les Données téléchargées.
        </p>

        <h3 className="w-10/12 text-xl pt-5 text-dark-lavender font-bold">
          ARTICLE 12. ENGAGEMENT CONCERNANT L’UTILISATION DU SITE ET DE SON
          CONTENU{" "}
        </h3>

        <p className="w-10/12 py-2 text-justify">
          L'Utilisateur s'engage à ne pas commettre, sur Nexum, d’acte illicite
          ou portant atteinte aux droits de la personne, et plus généralement
          aux intérêts de tiers.<br></br>
          Est prohibé par les Conditions générales d’utilisation, tout acte
          pouvant contribuer à ternir le nom et/ou l’image de TRIONODE SARL, ou
          pouvant endommager, surcharger, mettre hors d'état, discréditer ou
          mettre en échec Nexum.<br></br>
          Est notamment prohibé : la transmission, par courrier électronique ou
          par tout autre moyen au travers de la plateforme Nexum, des contenus
          servant à des fins de persécution, harcèlement, diffamation,
          discrimination, insultes, ou toute autre diffusion d'information ou
          d’opinion à caractère personnel sur un ou plusieurs individus ou
          portant atteinte aux droits de la personne ou aux Utilisateurs.
        </p>

        <h3 className="w-10/12 text-xl pt-5 text-dark-lavender font-bold">
          Article 13. SECURITE DU SITE
        </h3>

        <p className="w-10/12 py-2 text-justify">
          Nexum requiert une configuration internet suffisamment rapide, ainsi
          que des équipements et des moyens matériels permettant d’accéder et de
          naviguer sur Nexum, à la charge et aux frais de l’Utilisateur. Le
          navigateur doit être configuré pour autoriser les « cookies » de
          session. Il doit également être configuré afin d'autoriser l'ouverture
          de fenêtres pour le site Nexum. Afin de garantir un bon fonctionnement
          du Site, il est conseillé d’utiliser les versions récentes des
          navigateurs ci-dessous :<br></br>
          Google Chrome<br></br>
          Microsoft Edge<br></br>
          Apple Safari<br></br>
          Firefox<br></br>
          Opéra<br></br>
          <br></br>
          L’Utilisateur a conscience des risques inhérents à l’utilisation du
          réseau internet et en accepte les limites et les risques.<br></br>
          Le service IT fera ses meilleurs efforts pour sécuriser le Site au
          regard des risques encourus et de la nature des données traitées et en
          compliance avec les meilleurs standards de sécurité informatique.
          <br></br>
          Il est interdit à l’Utilisateur d’accéder ou de se maintenir,
          frauduleusement, dans tout ou partie du Site, de supprimer ou modifier
          les données contenues sur le Site, d’y introduire frauduleusement des
          données, d’altérer le Site ou de perturber son bon fonctionnement.
          <br></br>
          L’Utilisateur s’engage à ne pas tenter de modifier ou manipuler les
          pages du Site de manière à dissimuler, détourner ou modifier cette
          dernière.<br></br>
          Il est strictement interdit : (i) de reproduire, modifier, adapter,
          altérer le Site et/ou son contenu; (ii) d’accéder ou rechercher à
          accéder au code source du Site, en ayant recours à des techniques de
          décompilation, d’ingénierie inverse ou quelque autre moyen que ce
          soit; (iii) de rendre accessible ou de transmettre des parties du Site
          ou du contenu dont l’accès est restreint à disposition de tiers (iv)
          d’utiliser ou de tenter d’utiliser des Comptes autres que ceux qui lui
          sont attribués ou masquer son identité ; (v) d’utiliser le Site dans
          un but illicite, à ce titre, l’Utilisateur s’engage à n’inclure aucune
          information fausse, incomplète ou inexacte, à n’inclure aucun virus,
          cheval de Troie, ver, bombe à retardement ou tout autre programme
          conçu pour endommager, produire un effet néfaste, intercepter ou
          contrer tout système, donnée ou information personnelle.<br></br>
          Il doit le cas échéant disposer d’une connexion ou d’un réseau
          sécurisé, d’un antivirus, d’un système d’exploitation à jour pour
          prévenir tant que faire se peut tous dommages relatifs à une
          utilisation du Site non sécurisée. L’Utilisateur s’engage à prendre
          toutes les mesures appropriées de façon à protéger ses données et
          matériels, il est responsable de la sécurité de ses données et de son
          réseau qu’il utilise à ses propres risques.<br></br>
          Dans le cas où l’Utilisateur aurait connaissance d’un évènement
          pouvant porter atteinte à la sécurité du Site, telles qu’une erreur,
          faute, dysfonctionnement, intrusion, perte de données, vulnérabilité
          ou irrégularités, il est invité à contacter immédiatement le service
          IT par courriel à l’adresse email suivante :
          support@trionode.mc.
          <br></br>
          Sauf autorisation contraire, l’Utilisateur et son entreprise doivent :
          <br></br>
          Sauf si la loi l'exige, limiter l'accès au contenu de Nexum aux seules
          personnes qui ont un besoin direct et immédiat de cet accès et qui
          sont tenues d'en préserver la confidentialité.<br></br>
          Ne pas copier, télécharger, reproduire, modifier ou distribuer la
          Technologie de Nexum en tout ou en partie.<br></br>
          N’accéder qu'aux sections de Nexum auxquelles il lui est donné accès,
          et ne pas pirater ou tenter d'accéder d'une autre manière à d'autres
          sections de Nexum.<br></br>
          L’Utilisateur doit immédiatement cesser d'utiliser son Compte
          professionnel de Nexum si son emploi ou toute autre relation de
          travail avec sa Société est résilié pour quelque raison que ce soit.{" "}
          <br></br>
        </p>

        <h3 className="w-10/12 text-xl pt-5 text-dark-lavender font-bold">
          Article 14. PROPRIETE INTELLECTUELLE
        </h3>

        <p className="w-10/12 py-2 text-justify">
          Ce site est la propriété exclusive de TRIONODE SARL.<br></br>
          Les Conditions générales d’utilisation n'emportent aucune cession
          d'aucune sorte de droits de propriété intellectuelle au bénéfice de
          l’Utilisateur.
        </p>

        <h3 className="w-10/12 text-xl pt-5 text-dark-lavender font-bold">
          Article 15. DROITS D’AUTEUR
        </h3>

        <p className="w-10/12 py-2 text-justify">
          Tous les contenus (qu'il s'agisse de manière non exhaustive de textes,
          commentaires, titres, photographies, images, données, dessins, …)
          reproduits sur le site nexum.trionode.mc et preprod-nexum.trionode.mc,
          ainsi que la conception, la forme et les logiciels sont la propriété
          exclusive de TRIONODE SARL (sauf mentions spéciales) et sont protégés
          par les dispositions législatives en vigueur, relatives à la
          protection du droit d’auteur à Monaco. Seule la copie électronique à
          usage privé de tout ou partie du Site ou des éléments le composant est
          autorisée par TRIONODE SARL, sous réserve de ne pas en modifier
          l'original.
          <br></br>
          TRIONODE SARL accorde aux Utilisateurs du Site un droit d'usage
          professionnel, ponctuel et non exclusif, de celui-ci. <br></br>
          Toute forme de reproduction, de copie, d’utilisation, de modification,
          de diffusion, d’adaptation ou d'exploitation, dans un but commercial
          ou non, de tout élément ou du contenu du Site, sur quelque support et
          par quelque procédé que soit, dans un but autre que celui mentionné
          ci-dessus, est strictement prohibée et constitue une infraction pénale
          sanctionnée par devant les juridictions compétentes monégasques.{" "}
          <br></br>
        </p>

        <h3 className="w-10/12 text-xl pt-5 text-dark-lavender font-bold">
          Article 16. MARQUES
        </h3>

        <p className="w-10/12 py-2 text-justify">
          Les marques et/ou logos dont est titulaire TRIONODE SARL, apparaissant
          sur ce Site sont des marques protégées par des dispositifs du droit de
          la propriété intellectuelle. Toute reproduction de ces derniers, en
          tout ou partie, sans l'autorisation préalable écrite de TRIONODE SARL
          constitue une infraction pénale sanctionnée par devant les
          Juridictions compétentes monégasques.
        </p>

        <h3 className="w-10/12 text-xl pt-5 text-dark-lavender font-bold">
          Article 17. RESPONSABILITE ET FORCE MAJEURE
        </h3>

        <p className="w-10/12 py-2 text-justify">
          Il n'existe aucune garantie que Nexum sera disponible pour
          l’Utilisateur ou sa Société. <br></br>
          <br></br>
          TRIONODE SARL pourra modifier, suspendre ou résilier Nexum à tout
          moment, à sa seule discrétion.<br></br>
          <br></br>
          TRIONODE SARL ne garantit pas la continuité, l'accès et la
          disponibilité du fonctionnement du Site et des services afférents.{" "}
          <br></br>
          <br></br>
          TRIONODE SARL décline toute responsabilité relative aux virus qui
          pourraient être importés lors du téléchargement de l'un des éléments
          du Site ou des sites auxquels Nexum renvoie par le biais de liens
          hypertextes.
          <br></br>
          <br></br>
          La responsabilité de TRIONODE SARL ne peut être engagée en cas de
          force majeure ou du fait imprévisible et insurmontable causé par un
          tiers.
        </p>

        <h3 className="w-10/12 text-xl pt-5 text-dark-lavender font-bold">
          HYPERTEXTES
        </h3>

        <p className="w-10/12 py-2 text-justify">
          Les liens vers le Site et en direction d'autres sites doivent faire
          l'objet d'une autorisation préalable de la part de TRIONODE SARL.{" "}
          <br></br>
          <br></br>
          Cependant, TRIONODE SARL ne contrôle pas ces sites et ne pourrait être
          tenue responsable, ni supporter aucune responsabilité quant aux
          contenus, publicités, produits, services ou tout autre matériel
          disponible à partir de ces sites tiers. <br></br>
          TRIONODE SARL ne pourrait être tenue responsable de tous dommages ou
          pertes avérés ou allégués consécutifs ou en relation avec
          l'utilisation ou avec le fait d'avoir fait confiance au contenu des
          sites tiers ou aux biens et services disponibles sur ces sites tiers.{" "}
          <br></br>
          L'Utilisateur est responsable et supporte tous les risques afférents à
          l'utilisation qu'il fait du contenu du Site, notamment lorsqu'il se
          fie à l'opportunité, l'utilité ou le caractère complet de ce contenu.{" "}
          <br></br>
          Si un lien amenait l'Utilisateur à pointer vers des contenus
          illicites, en infraction avec la législation en vigueur, l'Utilisateur
          devrait alors interrompre sa consultation du site concerné, sauf à
          encourir les sanctions prévues par ladite législation et à répondre
          des actions en justice initiées à son encontre. <br></br>
          En pareille hypothèse, TRIONODE SARL invite l'Utilisateur à l'en
          informer immédiatement par courriel à l’adresse suivante :
          support@trionode.mc. <br></br>
          En tout état de cause, l’Utilisateur s’interdit d’engager la
          responsabilité de TRIONODE SARL concernant le contenu et les
          ressources relatives à ces liens hypertextes sortants.
        </p>

        <h3 className="w-10/12 text-xl pt-5 text-dark-lavender font-bold">
          Article 18. COOKIES
        </h3>

        <p className="w-10/12 py-2 text-justify">
          La plateforme Nexum utilise la technologie de suivi des « cookies ».
          Un « cookie » ne permet pas d’identifier l’Utilisateur. En revanche,
          il enregistre des informations relatives à la navigation de son
          ordinateur sur le Site.<br></br>
          <br></br>
          Parmi les « cookies » présents, sera récupéré le nom du PC établissant
          une identification sur Nexum. Cette donnée sera utilisée à des fins de
          sécurité.<br></br>
          <br></br>
          La durée de conservation de ces informations dans l’ordinateur de
          l’Utilisateur est de trois (3) ans. <br></br>
          <br></br>
          En tout état de cause, tout Utilisateur peut s’opposer à
          l'enregistrement de « cookies » en configurant cette option sur son
          navigateur. <br></br>
          <br></br>
        </p>

        <h3 className="w-10/12 text-xl pt-5 text-dark-lavender font-bold">
          Article 19. Evolution des Conditions générales d’utilisation
        </h3>

        <p className="w-10/12 py-2 text-justify">
          Les termes des Conditions générales d’utilisation peuvent être amendés
          à tout moment, sans préavis, en fonction des modifications opérées par
          le service IT, de l’évolution de la législation ou de tout autre motif
          qui sera jugé nécessaire. <br></br>
          <br></br>
          Il appartient à l’Utilisateur de s’informer desdites conditions
          modifiées qui apparaîtront dans ce but sur le Site dès leur
          modification pour acceptation par l’Utilisateur.
          <br></br>
        </p>
        <h3 className="w-10/12 text-xl pt-5 text-dark-lavender font-bold">
          Article 20. Durée{" "}
        </h3>

        <p className="w-10/12 py-2 text-justify">
          La durée des Conditions générales d’utilisation est indéterminée et
          ces dernières produisent leurs effets à l’égard de l’Utilisateur à
          compter de la première utilisation de Nexum.
        </p>
        <h3 className="w-10/12 text-xl pt-5 text-dark-lavender font-bold">
          Article 21. Droit applicable et juridiction compétente
        </h3>

        <p className="w-10/12 py-2 text-justify mb-40">
          Les Conditions générales d’utilisation sont régies par le seul droit
          monégasque. Tout différend relatif à l’existence, la validité,
          l’interprétation, l’exécution ou se référant plus largement pour
          quelque raison qu’il soit aux Conditions générales d’utilisation et
          qui ne saurait se résoudre de façon amiable, sera réglé de manière
          exclusive par devant les tribunaux compétents de la Principauté de
          Monaco.
        </p>
      </div>
    </div>
  );
};

export default CGU;
