import Dropzone from "react-dropzone";
import { usePartnerRequestsStore2 } from "../../../../stores/Cac/Partner/partnerRequests";
import { handleUploadFiles } from "../../../../services/Api/requestService";
import {
  showToastError,
  showToastSuccess,
} from "../../../../services/Toasts/toasts";

export const DropZoneDocument = ({ requestId }: any) => {
  const { updateRequest, setUpdateRequest } = usePartnerRequestsStore2();

  const handleUploadClick = (files: File[]) => {
    handleUploadFiles(requestId, files)
      .then(() => {
        setUpdateRequest(!updateRequest);
        showToastSuccess("Fichier téléchargé avec succès");
      })
      .catch((error) => {
        console.log(error);
        showToastError(
          "Format accepté : csv, doc, docx, jpeg, pmg, pdf, txt, xls, xlsx, zip"
        );
      });
  };

  const handleDrop = (acceptedFiles: File[]) => {
    handleUploadClick(acceptedFiles);
  };

  return (
    <Dropzone onDrop={handleDrop}>
      {({ getRootProps, getInputProps }) => (
        <div
          {...getRootProps()}
          className="w-10/12 m-auto bg-zinc-300 border-2 border-dashed	hover:bg-zinc-200 transition-all duration-150 border-zinc-100 rounded-md h-20 mb-2 cursor-pointer flex flex-col items-center justify-center"
        >
          <input {...getInputProps()} />
          <p className="text-center text-gray-500 hover:text-gray-800 font-BarlowCondensed">
            Glissez et déposez un fichier ici, <br></br> ou cliquez pour
            sélectionner un fichier
          </p>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="gray"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
            className="feather feather-upload-cloud"
          >
            <polyline points="16 16 12 12 8 16"></polyline>
            <line x1="12" y1="12" x2="12" y2="21"></line>
            <path d="M20.39 18.39A5 5 0 0 0 18 9h-1.26A8 8 0 1 0 3 16.3"></path>
            <polyline points="16 16 12 12 8 16"></polyline>
          </svg>
        </div>
      )}
    </Dropzone>
  );
};
