import { create } from "zustand";

export type SuperAdminCreateUserPartnerStore = {
    eMail: string;
    setEmail: (eMail: string) => void;

    selectedRole: any;
    setSelectedRole: (selectedRole: any) => void;

    idPartner: number;
    setIdPartner: (idPartner: number) => void;

    roles : any[];
    setRoles: (roles: any[]) => void;

    imgDownload: any;
    setImgDownload: (imgDownload: any) => void;

    forceCall: boolean;
    setForceCall: (forceCall: boolean) => void;

    reset: () => void;
}


export const useSuperAdminCreateUserPartnerStore = create<SuperAdminCreateUserPartnerStore>((set) => ({
    eMail: "",
    setEmail: (eMail: string) => set({ eMail }),

    selectedRole: 0,
    setSelectedRole: (selectedRole: any) => set({selectedRole}),

    idPartner: 0,
    setIdPartner: (idPartner: number) => set({ idPartner }),

    roles: [],
    setRoles: (roles: any) => set({roles}),

    imgDownload: "",
    setImgDownload: (imgDownload: string) => set({imgDownload}),

    forceCall: false,
    setForceCall: (forceCall: boolean) => set({ forceCall: forceCall }),

    reset: () => set({
        eMail: "",
        idPartner: 0,
        selectedRole: 0,
        roles: [],
    }),
}));