import React from "react";
import useModal from "../../../hooks/useModal";
import Trash from "../../../assets/icons/trash.svg";
import Archive from "../../../assets/icons/archive.svg";
import ArchiveDeleteFile from "../Modales/ArchiveDeleteFile";
import DeleteClientFile from "../Modales/DeleteClientFile";
import { useServiceStore } from "../../../stores/admin/service";
import { useMissionStore } from "../../../stores/admin/mission";
import {
  dateFormat,
  splitMissionName,
} from "../../../services/Utils/functionService";

const MissionsAdminTable = () => {
  // Store
  const { missions, editingMissionId } = useMissionStore();

  // Hook
  const { modalContent, isModalOpen, openModal, closeModal } = useModal();

  return (
    <div className="h-full w-full flex">
      {isModalOpen ? modalContent : null}
      <div className="flex flex-col w-full h-full font-BarlowCondensed">
        <div className="w-full m-auto py-2 grid grid-cols-7 grid-rows-1 border-b-[1px] border-zinc-200 text-grey-trio bg-white text-xl">
          <div className="col-start-1 col-end-2 font-bold">Service</div>
          <div className="col-start-2 col-end-4 font-bold">Nom</div>
          <div className="col-start-4 col-end-5 font-bold">Responsable</div>
          <div className="col-start-5 col-end-6 font-bold">Date de fin</div>
          <div className="col-start-6 col-end-7"></div>
        </div>
        <div className="w-full h-full overflow-y-scroll bg-white mb-5">
          {missions?.map((mission: any) => (
            <div
              key={mission?.id}
              className={
                editingMissionId === mission?.id
                  ? "w-full bg-gray-100 m-auto grid grid-cols-7 h-10 2xl:text-xl text-md font-lightjustify-start items-center"
                  : mission?.closed === 1
                  ? "w-full bg-gray-100 m-auto grid grid-cols-7 h-10 italic text-gray-400 2xl:text-xl text-md font-light justify-start items-center"
                  : "w-full hover:bg-light-lavender hover:bg-opacity-20 transition-all duration-150 m-auto grid grid-cols-7 h-10  2xl:text-xl text-md font-light justify-start items-center"
              }
            >
              <div className="col-start-1 col-end-2 flex items-center">
                {mission?.CategoryMission?.name}
              </div>
              <div className="col-start-2 col-end-4 flex items-center">
                {splitMissionName(mission?.name)}
              </div>
              <div className="col-start-4 col-end-5 flex items-center ml-2">
                {mission?.UserToMissions[0]?.User?.fullName}
              </div>
              <div className="col-start-5 col-end-6 ml-2 flex items-center">
                {dateFormat(mission?.dueDate)}
              </div>
              <div className="col-start-6 col-end-7 grid grid-cols-3 ">
                <div className="col-start-1 col-end-2 flex items-center pt-1">
                  <button
                    onClick={() =>
                      openModal(
                        <ArchiveDeleteFile
                          closed={mission?.closed}
                          id={mission?.id}
                          closeModal={closeModal}
                          idCustomer={mission?.Customer?.id}
                        />
                      )
                    }
                  >
                    <img src={Archive} alt="archive" className="mr-5" />
                  </button>
                </div>
                <div className="col-start-2 col-end-3 flex items-center pt-1">
                  <button
                    onClick={() =>
                      openModal(
                        <DeleteClientFile
                          id={mission?.id}
                          closeModal={closeModal}
                        />
                      )
                    }
                  >
                    <img src={Trash} alt="trash" />
                  </button>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default React.memo(MissionsAdminTable);
