import React, { useState } from "react";
import { usePartnerMissionStore } from "../../../../stores/Cac/Partner/partnerMission";
import {
  showToastError,
  showToastSuccess,
} from "../../../../services/Toasts/toasts";
import {
  archiveMission,
  handleExportCSV,
} from "../../../../services/Api/customerService";
import { useParams } from "react-router-dom";
import { set } from "date-fns";

const PartnerModalArchiveMission = () => {
  const { idCustomer } = useParams();
  // Store
  const { missionId, setChoiceModal } = usePartnerMissionStore();
  const [value, setValue] = useState<string>("");

  // Function for verify value for archive mission and download csv
  const verifyValue = async (value: string) => {
    if (value === "archiver") {
      try {
        const response = await archiveMission(
          Number(idCustomer),
          Number(missionId)
        );
        if (response?.status === 200) {
          showToastSuccess("L'archivage a bien été effectué !");
          setValue("");
          setChoiceModal("");
          handleExportCSV(Number(idCustomer), Number(missionId));
        } else {
          showToastError(
            "L'archivage a échoué car il reste une ou plusieurs requête(s) non acceptée(s)!"
          );
        }
      } catch (error) {
        console.log("Erreur lors de l'appel à archiveMission", error);
      }
    } else {
      showToastError("Erreur lors de l'archivage");
    }
  };

  const handleKeyDown = (e: React.KeyboardEvent) => {
    if (e.key === "Enter") {
      verifyValue(value);
    }
  };
  
  return (
    <>
      <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
        <div className="relative w-auto lg:w-1/4 my-6 mx-auto max-w-3xl min-w-[35%]">
          {/*content*/}
          <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
            {/*header*/}
            <div className="flex items-center justify-between p-5 border-b border-solid border-slate-200 rounded-t">
              <h3 className="text-xl sm:text-3xl w-full font-semibold font-K2D text-center text-grey-trio">
                Archivage
              </h3>
            </div>
            {/*body*/}
            <div className="text-sm md:text-xl text-center p-5 text-grey-trio font-BarlowCondensed">
              <div
                className={`flex flex-col justify-center items-center w-10/12 m-auto`}
              >
                <label className="text-center text-lg mb-2 font-BarlowCondensed">
                  Pour archiver cette mission, veuillez confirmer en écrivant le mot "archiver" dans le champ ci-dessous.
                </label>
                <div className="flex w-6/12 m-auto items-center justify-center">
                  <input
                    type="text"
                    className="border-[1px] rounded-md w-80 h-10 p-1 focus:outline-none shadow-lg "
                    onKeyDown={handleKeyDown}
                    onChange={(e) => {
                     const value = e.target.value;
                     setValue(value);
                    }}
                  />
                </div>
              </div>
            </div>
            {/*footer*/}
            <div className="flex flex-col ">
              <div className="flex items-center justify-end p-4 border-t border-solid border-slate-200 rounded-b font-BarlowCondensed">
                <button
                  className="text-red-300 background-transparent hover:text-red-500 font-bold uppercase px-6 py-2 2xl:text-lg text-base  outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
                  type="button"
                  onClick={() => setChoiceModal("")}
                >
                  Annuler
                </button>
                <button
                  className="text-white bg-gradient-to-r from-light-lavender via-light-lavender to-dark-lavender hover:bg-gradient-to-br font-medium rounded-lg 2xl:text-lg text-base  px-5 py-2.5 text-center me-2 uppercase  shadow hover:shadow-lg outline-none  ease-linear transition-all duration-150"
                  type="button"
                  onClick={() => {
                    if (value.toLowerCase() === "archiver") {
                      verifyValue(value);
                    }
                  }}
                >
                  Archiver
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
    </>
  );
};

export default PartnerModalArchiveMission;
