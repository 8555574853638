import {
  dateFormat,
  fileNameFormat,
  hourFormat,
} from "../../../../services/Utils/functionService";
import { downloadDoc, openDoc } from "../../../../services/Api/documentService";
import Eye from "../../../../assets/icons/eye.svg";
import Download from "../../../../assets/icons/download.svg";
import Trash from "../../../../assets/icons/trash.svg";
import { usePartnerRequestDetailsStore } from "../../../../stores/Cac/Partner/partnerRequestDetails";
import ModalDeleteDoc from "../Utils/ModalDeleteDoc";

const PartnerDocumentTable = ({ openModal, closeModal }: any) => {
  // Store
  const { request } = usePartnerRequestDetailsStore();

  return (
    <div
      className={`w-10/12 md:h-[50%] xl:h-[65%] 2xl:h-[70%] bg-white m-auto rounded-md shadow-md overflow-y-scroll font-BarlowCondensed `}
    >
      {!request?.Documents || request?.Documents.length === 0 ? (
        <p className="text-center font-extralight pt-4">
          Il n'y a aucun documents déposés ...
        </p>
      ) : (
        <div className="flex flex-col w-full py-2">
          {request?.Documents.map(
            (document: {
              filename: string;
              type: string;
              createdAt: string;
              id: number;
              user: string;
            }) => (
              <div
                className="flex flex-col w-full m-auto pt-2 hover:bg-gray-200"
                key={document?.id}
              >
                <div className="grid grid-cols-3 w-full overflow-x-hidden">
                  <div className="text-slate-600 text-lg font-semibold col-start-1 col-end-3 pl-5 flex overflow-hidden whitespace-nowrap text-overflow-ellipsis pr-4 mr-2">
                    {fileNameFormat(document?.filename)}
                  </div>
                  <div className="grid grid-cols-3 col-start-3 col-end-4">
                    {document.type === "xlsx" || document.type === "zip" ? (
                      <div className="col-start-1 col-end-2"></div>
                    ) : (
                      <button
                        type="button"
                        onClick={() => {
                          openDoc(request?.id, document.id);
                        }}
                      >
                        <img
                          src={Eye}
                          alt="visualisation"
                          className="col-start-1 col-end-2"
                        />
                      </button>
                    )}
                    <button
                      type="button"
                      onClick={() => {
                        downloadDoc(
                          request?.id,
                          document.id,
                          document.filename
                        );
                      }}
                    >
                      <img
                        src={Download}
                        alt="visualisation"
                        className="col-start-2 col-end-2"
                      />
                    </button>
                    {request?.State?.state === "Accepté" ? (
                      <></>
                    ) : (
                      <button
                        type="button"
                        onClick={() =>
                          openModal(
                            <ModalDeleteDoc
                              idRequest={request?.id}
                              idDoc={document.id}
                              closeModal={closeModal}
                            />
                          )
                        }
                      >
                        <img
                          className="col-start-3 col-end-4"
                          src={Trash}
                          alt="trash"
                        />
                      </button>
                    )}
                  </div>
                </div>
                <div className="flex items-center justify-start pl-5 py-2">
                  <p className="text-slate-600 text-md font-extralight pr-5">
                    Date upload : {dateFormat(document?.createdAt)} à{" "}
                    {hourFormat(document?.createdAt)}
                  </p>
                  <p className="text-slate-600 text-md font-extralight pr-5">
                    Par : {document?.user}
                  </p>
                  <p className="text-slate-600 text-md font-extralight">
                    Type : {document?.type}
                  </p>
                </div>
                <hr className="w-10/12 m-auto py-2" />
              </div>
            )
          )}
        </div>
      )}
    </div>
  );
};

export default PartnerDocumentTable;
