import {
  dateFormat,
  formatDate,
} from "../../../../services/Utils/functionService";
import { usePartnerWorkingFolderStore } from "../../../../stores/Cac/Partner/partnerWorkingFolder";
import trash from "../../../../assets/icons/trash.svg";
import send from "../../../../assets/icons/send.svg";
import { useParams } from "react-router-dom";
import {
  revokeAccessWorkingFolder,
  sendNewAccessWorkingFolder,
  userAssignToWorkingFolder,
  userUnassignToWorkingFolder,
} from "../../../../services/Api/customerService";
import {
  showToastError,
  showToastSuccess,
} from "../../../../services/Toasts/toasts";

const UsersAffectedWorkingFolder = () => {
  const {
    workingFolderDetails,
    updateWorkingFolder,
    setUpdateWorkingFolder,
    collabsAssignedToClient,
    checkboxValuesCollabs,
  } = usePartnerWorkingFolderStore();
  const { idCustomer, idWorkingFolder } = useParams();

  const handleRevokeAccess = (email: string) => {
    const data = {
      email: email,
    };
    revokeAccessWorkingFolder(Number(idCustomer), Number(idWorkingFolder), data)
      .then(() => {
        setUpdateWorkingFolder(!updateWorkingFolder);
        showToastSuccess("L'accès a été révoqué avec succès");
      })
      .catch(() => {
        console.log("error");
        showToastError("Une erreur s'est produite. Veuillez réessayer.");
      });
  };

  const handleSendAccess = (email: string) => {
    const data = {
      email: email,
    };
    sendNewAccessWorkingFolder(
      Number(idCustomer),
      Number(idWorkingFolder),
      data
    )
      .then(() => {
        setUpdateWorkingFolder(!updateWorkingFolder);
        showToastSuccess("L'accès a été envoyé avec succès");
      })
      .catch(() => {
        console.log("error");
        showToastError("Une erreur s'est produite. Veuillez réessayer.");
      });
  };

  const handleCheckboxChangeCollabs = (value: number, checked: boolean) => {
    if (!checked) {
      const data = {
        idsUsers: [value],
      };
      userUnassignToWorkingFolder(
        Number(idCustomer),
        Number(idWorkingFolder),
        data
      )
        .then(() => {
          setUpdateWorkingFolder(!updateWorkingFolder);
          showToastSuccess("L'utilisateur a été retiré avec succès");
        })
        .catch(() => {
          console.log("error");
          showToastError("Une erreur s'est produite. Veuillez réessayer.");
        });
    } else {
      const data = {
        idsUsers: [value],
      };
      userAssignToWorkingFolder(
        Number(idCustomer),
        Number(idWorkingFolder),
        data
      )
        .then(() => {
          setUpdateWorkingFolder(!updateWorkingFolder);
          showToastSuccess("L'utilisateur a été ajouté avec succès");
        })
        .catch(() => {
          console.log("error");
          showToastError("Une erreur s'est produite. Veuillez réessayer.");
        });
    }
  };

  return (
    <div className="w-full flex flex-col justify-start items-start h-full mb-20">
      <div className="w-full rounded-md h-1/2 bg-white shadow-2xl font-BarlowCondensed text-lg font-thin overflow-hidden">
        <table className="w-full border-none">
          <thead className=" h-10 font-normal w-full text-grey-trio border-none">
            <tr className="w-full ">
              <th className="w-[45%] text-start pl-2">Utilisateurs affectés</th>
              <th className="w-[15%] text-start pl-2">Date envoi</th>
              <th className="w-[10%] text-start pr-2">Téléchargé</th>
              <th className="w-[15%] text-start pr-2">Date</th>
              <th className="w-[5%] text-start pr-1">Accès</th>
              <th className="w-[10%] text-start pr-1"></th>
            </tr>
          </thead>
          <tbody className="overflow-y-scroll text-grey-trio w-full">
            {workingFolderDetails?.workingFolder?.accessUsers?.map(
              (user: any) => (
                <tr
                  key={user.id}
                  className="h-10 w-full"
                >
                  <td className="w-[45%] pl-1">{user.email}</td>
                  <td className="w-[15%] pl-1">{formatDate(user.sendDate)}</td>
                  <td className="w-[10%] pl-5 text-sm">
                    {user.dateDownload !== null ? "✅" : "❌"}
                  </td>
                  <td className="w-[15%]">
                    {user.dateDownload !== null
                      ? dateFormat(user.dateDownload)
                      : ""}
                  </td>
                  <td className="w-[5%]">
                    <button onClick={() => handleSendAccess(user.email)}>
                      <img src={send} alt="send" className="w-5 h-5" />
                    </button>
                  </td>
                  <td className="w-[10%]">
                    <button onClick={() => handleRevokeAccess(user.email)}>
                      <img
                        src={trash}
                        alt="pourbelle"
                        className="w-5 h-5 ml-5"
                      />
                    </button>
                  </td>
                </tr>
              )
            )}
          </tbody>
        </table>
      </div>
      <div className="w-full rounded-md h-1/2 bg-white shadow-2xl font-BarlowCondensed text-lg font-thin overflow-hidden mt-10">
        <table className="w-full border-none">
          <thead className=" h-10 text-grey-trio font-thin">
            <tr className="w-full">
              <th className="w-[40%] text-start pl-2">Prénom</th>
              <th className="w-[40%] text-start pl-1">Nom</th>
              <th className="w-[20%] text-start pr-1">Assignation</th>
            </tr>
          </thead>
          <tbody className="overflow-y-scroll">
            {collabsAssignedToClient?.map((user: any) => (
              <tr
                key={user.idUser}
                className="h-10 w-full"
              >
                <td className="w-[40%] pl-2">{user.firstName}</td>
                <td className="w-[40%]">{user.lastName}</td>
                <td className="w-[20%]">
                  <input
                    type="checkbox"
                    checked={checkboxValuesCollabs[user?.id] || false}
                    onChange={() => {
                      handleCheckboxChangeCollabs(
                        user?.id,
                        !checkboxValuesCollabs[user?.id]
                      );
                    }}
                    className="mr-2 ml-5 w-4 h-4"
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default UsersAffectedWorkingFolder;
