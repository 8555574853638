import { usePartnerMissionStore } from "../../../../stores/Cac/Partner/partnerMission";

const PopupLimitMission = () => {

  // Store
  const { setErrorLimitMission } = usePartnerMissionStore();

  return (
    <>
      <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none min-w-full">
        <div className="relative w-auto md:w-1/4 my-6 mx-auto max-w-3xl min-w-[25%]">
          {/*content*/}
          <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
            {/*header*/}
            <div className="flex items-center justify-center p-5 border-b border-solid border-slate-200 rounded-t">
              <h3 className="text-base md:text-3xl font-semibold text-center text-grey-trio">
                Nombres de missions atteint
              </h3>
            </div>
            {/*body*/}
            <div className="text-sm md:text-base text-center p-5 text-grey-trio">
                Vous avez atteint le nombre maximum de missions actives autorisées. Veuillez contacter notre support à l'adresse : <br />
                <span className="italic underline font-extrabold">support@trionode.mc</span>
                
            </div>
            {/*footer*/}
            <div className="flex items-center justify-end p-4 border-t border-solid border-slate-200 rounded-b">
              <button
                className="text-white bg-gradient-to-r from-light-lavender via-light-lavender to-dark-lavender hover:bg-gradient-to-br font-medium rounded-lg 2xl:text-lg text-base  px-5 py-2.5 text-center me-2 uppercase  shadow hover:shadow-lg outline-none  ease-linear transition-all duration-150"
                type="button"
                onClick={() => {
                  setErrorLimitMission(false);
                }}
              >
                Ok
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
    </>
  )
}

export default PopupLimitMission
