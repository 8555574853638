import { useLocation } from "react-router-dom";

const StatusRequest = () => {
  const location = useLocation();
  const currentPage = location.pathname;
  if (currentPage.includes("monitoring")) {
    return (
      <div className="grid grid-cols-2 font-BarlowCondensed font-thin">
        <div className="col-start-1 flex items-center">
          <div className="w-6 h-3 border-[1px] rounded-md border-filter-yellow bg-filter-yellow mx-2"></div>
          <p className="mr-4 font-extralight">En attente</p>
        </div>
        <div className="col-start-2 flex items-center">
          <div className="w-6 h-3 border-[1px] rounded-md border-filter-orange bg-filter-orange mx-2"></div>
          <p className="mr-4 font-extralight">En retard</p>
        </div>
        <div className="col-start-1 flex items-center">
          <div className="w-6 h-3 border-[1px] rounded-md border-filter-blue bg-filter-blue mx-2"></div>
          <p className="mr-4 font-extralight">Délivré</p>
        </div>
        <div className="col-start-2 flex items-center">
          <div className="w-6 h-3 border-[1px] rounded-md border-filter-green  bg-filter-green mx-2"></div>
          <p className="font-extralight">Accepté</p>
        </div>
      </div>
    );
  } else if (currentPage.includes("/recoveryfile")) {
    return (
      <div className="flex items-center justify-center font-BarlowCondensed font-thin text-nxm-darkGray">
        <div className="w-6 h-3 border-[1px] rounded-md border-filter-yellow bg-filter-yellow mr-2"></div>
        <p className="mr-4 font-extralight ">En attente</p>
        <div className="w-6 h-3 border-[1px] rounded-md border-filter-orange bg-filter-orange mr-2"></div>
        <p className="mr-4 font-extralight ">En retard</p>
        <div className="w-6 h-3 border-[1px] rounded-md border-filter-blue bg-filter-blue mr-2"></div>
        <p className="mr-4 font-extralight ">Délivré</p>
        <div className="w-6 h-3 border-[1px] rounded-md border-filter-green  bg-filter-green mr-2"></div>
        <p className="font-extralight ">Accepté</p>
      </div>
    );
  } else {
    return (
      <div className="hidden xl:flex items-center justify-center font-BarlowCondensed font-thin text-grey-trio">
        <p className="mr-4 font-extralight italic">Statuts des requêtes : </p>
        <div className="w-6 h-3 border-[1px] rounded-md border-filter-yellow bg-filter-yellow mr-2"></div>
        <p className="mr-4 font-extralight">En attente</p>
        <div className="w-6 h-3 border-[1px] rounded-md border-filter-orange bg-filter-orange mr-2"></div>
        <p className="mr-4 font-extralight">En retard</p>
        <div className="w-6 h-3 border-[1px] rounded-md border-filter-blue bg-filter-blue mr-2"></div>
        <p className="mr-4 font-extralight">Délivré</p>
        <div className="w-6 h-3 border-[1px] rounded-md border-filter-green  bg-filter-green mr-2"></div>
        <p className="font-extralight">Accepté</p>
      </div>
    );
  }
};

export default StatusRequest;
