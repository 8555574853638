import { create } from "zustand";

type ConnexionState = {
    displayPopUpCookie: boolean;
    setDisplayPopUpCookie: (value: boolean) => void;

    email: string;
    setEmail: (value: string) => void;

    password: string;
    setPassword: (value: string) => void;

    showModalForgotPassword: boolean;
    setShowModalForgotPassword: (value: boolean) => void;

    showModalVerifyEmail: boolean;
    setShowModalVerifyEmail: (value: boolean) => void;

    passwordInputType: string;
    setPasswordInputType: (value: string) => void;

    showModal2fa: boolean;
    setShowModal2fa: (value: boolean) => void;

    code2fa: string;
    setCode2fa: (value: string) => void;

    validate2WeeksWithout2fa: boolean;
    setValidate2WeeksWithout2fa: (value: boolean) => void;

    passwordResetPassword: string;
    setPasswordResetPassword: (value: string) => void;

    verifyPasswordResetPassword: string;
    setVerifyPasswordResetPassword: (value: string) => void;
};

export const useConnexionStore = create<ConnexionState>((set) => ({
    displayPopUpCookie: true,
    setDisplayPopUpCookie: (value: boolean) => set({ displayPopUpCookie: value }),

    email: "",
    setEmail: (value: string) => set({ email: value }),

    password: "",
    setPassword: (value: string) => set({ password: value }),

    showModalForgotPassword: false,
    setShowModalForgotPassword: (value: boolean) => set({ showModalForgotPassword: value }),

    showModalVerifyEmail: false,
    setShowModalVerifyEmail: (value: boolean) => set({ showModalVerifyEmail: value }),

    passwordInputType: "password",
    setPasswordInputType: (value: string) => set({ passwordInputType: value }),

    showModal2fa: false,
    setShowModal2fa: (value: boolean) => set({ showModal2fa: value }),

    code2fa: "",
    setCode2fa: (value: string) => set({ code2fa: value }),

    validate2WeeksWithout2fa: false,
    setValidate2WeeksWithout2fa: (value: boolean) => set({ validate2WeeksWithout2fa: value }),

    passwordResetPassword: "",
    setPasswordResetPassword: (value: string) => set({ passwordResetPassword: value }),

    verifyPasswordResetPassword: "",
    setVerifyPasswordResetPassword: (value: string) => set({ verifyPasswordResetPassword: value }),
}));