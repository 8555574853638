import { getInvoicePdfByPartner } from "../../../services/Api/partnerService";
import { dateFormat } from "../../../services/Utils/functionService";
import { useInvoiceStore } from "../../../stores/admin/invoices";
import pdf from "../../../assets/icons/pdf.png";
import { useParams } from "react-router-dom";

const InvoicesAdminTable = () => {
  const { invoices } = useInvoiceStore();
  const { idPartner } = useParams();

  const handleDownload = (idPartner: number, idInvoice: number) => {
    getInvoicePdfByPartner(idPartner, idInvoice)
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "invoice.pdf");
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      })
      .catch((error) => console.log(error));
  };

  return (
    <div className="h-full w-full rounded-md flex">
      <div className="flex flex-col w-full h-full font-BarlowCondensed">
        <div className="w-full m-auto py-2 border-zinc-100 grid grid-cols-7 grid-rows-1 text-xl border-b-[1px]  text-grey-trio bg-white">
          <p></p>
          <p className="font-bold">Date</p>
          <p className="font-bold">Numéro de facture</p>
          <p className="font-bold">Total remise</p>
          <p className="font-bold">Montant TVA</p>
          <p className="font-bold">Montant HT</p>
          <p className="font-bold">Montant TTC</p>
        </div>
        <div className="w-full h-full overflow-y-scroll bg-white mb-5">
          {invoices?.map((invoice: any) => (
            <div
              key={invoice.id}
              className="w-full grid grid-cols-7  hover:bg-light-lavender transition-all duration-150 hover:bg-opacity-20 text-md h-auto p-2  "
            >
              <button
                onClick={() => {
                  handleDownload(Number(idPartner), invoice.id);
                }}
              >
                <img src={pdf} alt="pdf" className="w-6 h-7" />
              </button>
              <p>{dateFormat(invoice.date)}</p>
              <p className="ml-1">{invoice.invoiceNumber} €</p>
              <p className="ml-2">{invoice.discountAmount} €</p>
              <p className="ml-2">{invoice.vatAmount} €</p>
              <p className="ml-3">{invoice.grossAmount} €</p>
              <p className="ml-4">{invoice.netAmount} €</p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default InvoicesAdminTable;
