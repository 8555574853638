import { useEffect, useState } from "react";
import { useTokenContext } from "../../context/tokenContext";
import { useMfaStore } from "../../stores/Utils/mfa";
import {
  configMfa,
  enableMfa,
  verifytotpForDesactivated,
} from "../../services/Api/userService";
import { useSettingsStore } from "../../stores/Utils/settings";
import { showToastError, showToastSuccess } from "../../services/Toasts/toasts";
import AuthCode from "react-auth-code-input";
import { ToastContainer } from "react-toastify";

const Mfa = () => {
  const { showMfa, setShowMfa } = useMfaStore();
  const { setShowSettings } = useSettingsStore();
  // Context
  const { mfa, setMfa } = useTokenContext();
  const [updateMfa, setUpdateMfa] = useState<boolean>(
    localStorage.getItem("mfa") === "true" ? true : false
  );
  const [openVerify, setOpenVerify] = useState<boolean>(false);
  const [data, setData] = useState({ qrcode: "", code: "" });
  const [codeMfa, setCodeMfa] = useState("");

  const toggleMfa = (newUpdateMfa: boolean) => {
    if (newUpdateMfa) {
      setMfa(newUpdateMfa);
      configMfa()
        .then((response) => {
          setData({
            qrcode: response?.data?.qrcode,
            code: response?.data?.code,
          });
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      localStorage.setItem("mfa", String(false));
      setOpenVerify(true);
    }
  };

  useEffect(() => {
    if (updateMfa === false) {
      setMfa(false);
      setData({ qrcode: "", code: "" });
    }
  }, [updateMfa]);

  const handleDesactivateMfa = () => {
    setUpdateMfa(false);
    verifytotpForDesactivated(codeMfa)
      .then(() => {
        setMfa(false);
        localStorage.removeItem("mfa");
        setShowSettings(false);
        setShowMfa(false);
        showToastSuccess("Votre MFA à été bien désactivé.");
      })
      .catch((err) => {
        console.log(err);
        if (err) showToastError("Le code MFA est incorrect.");
      });
  };

  const handleActivateMfa = () => {
    enableMfa(codeMfa)
      .then(() => {
        setShowSettings(false);
        setShowMfa(false);
        localStorage.setItem("mfa", String(true));
        showToastSuccess("Votre MFA à été bien activé.");
      })
      .catch((err: any) => {
        console.log(err);
        showToastError("Erreur lors de l'activation du MFA");
      });
  };

  const handleKeyDown = (event: React.KeyboardEvent) => {
    if (event.key === "Enter") {
      if (openVerify === true) {
        handleDesactivateMfa();
      } else {
        handleActivateMfa();
      }
    }
  };

  return (
    showMfa && (
      <>
        <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none min-w-full">
          <ToastContainer position="top-center" />
          <div className="relative w-3/4 md:w-1/4 my-6 mx-auto max-w-3xl min-w-[35%]">
            {/*content*/}
            <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
              {/*header*/}
              <div className="flex items-start justify-center p-5 border-b border-solid border-slate-200 rounded-t">
                <h3 className="text-xl xl:text-3xl font-semibold text-center text-grey-trio font-K2D ">
                  Configurer votre MFA
                </h3>
              </div>
              {/*body*/}
              <div className="text-sm xl:text-base text-center p-5 flex flex-col justify-center items-center font-BarlowCondensed">
                <div className="flex justify-start items-center">
                  <p className="mr-4 font-bold text-xl text-grey-trio">
                    ACTIVER MFA :
                  </p>
                  <label className="inline-flex items-center cursor-pointer">
                    <input
                      type="checkbox"
                      checked={updateMfa}
                      className="sr-only peer"
                      onChange={() => {
                        const newUpdateMfa = !updateMfa;
                        setUpdateMfa(newUpdateMfa);
                        toggleMfa(newUpdateMfa);
                      }}
                    />
                    <div className="relative w-11 h-6 bg-gray-200 peer-focus:outline-none  peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>
                  </label>
                </div>
                {data.qrcode !== "" ? (
                  <div className="flex flex-col justify-center items-center">
                    <img
                      src={data.qrcode}
                      alt="QR Code"
                      className="w-36 h-36 my-5"
                    />
                  </div>
                ) : null}
                {data.qrcode !== "" ? (
                  <div className="flex flex-col justify-center items-center w-11/12 m-auto">
                    <p className="">
                      Pour activer le MFA vous devez saisir le code renseigner
                      sur votre application authenticateur, installé sur votre
                      smartphone.{" "}
                    </p>
                    <AuthCode
                      allowedCharacters="numeric"
                      length={6}
                      onChange={(value) => setCodeMfa(value)}
                      containerClassName="my-4"
                      inputClassName="border-[1px] rounded-md border-gray-200 w-8 lg:w-10 h-12 lg:mr-2 mr-1 text-center shadow-md"
                    />
                  </div>
                ) : null}
                {openVerify ? (
                  <div className="flex flex-col justify-center items-center w-11/12 m-auto">
                    <p className="">
                      Pour désactiver le MFA vous devez saisir le code
                      renseigner sur votre application authenticateur, installé
                      sur votre smartphone.{" "}
                    </p>
                    <AuthCode
                      allowedCharacters="numeric"
                      length={6}
                      onChange={(value) => {
                        handleKeyDown;
                        setCodeMfa(value);
                      }}
                      containerClassName="my-4"
                      inputClassName="border-[1px] rounded-md border-gray-200 w-8 lg:w-10 h-12 lg:mr-2 mr-1 text-center shadow-md"
                    />
                  </div>
                ) : null}
              </div>
              {/*footer*/}
              <div className="flex items-center justify-end p-4 border-t border-solid border-slate-200 rounded-b font-BarlowCondensed">
                <button
                  className="text-red-300 background-transparent hover:text-red-500 font-bold uppercase px-6 py-2 2xl:text-lg text-base  outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
                  type="button"
                  onClick={() => {
                    if (mfa === true) {
                      setShowSettings(false);
                      setShowMfa(false);
                      setUpdateMfa(true);
                      setOpenVerify(false);
                    } else {
                      setShowMfa(false);
                      setShowSettings(false);
                      setUpdateMfa(false);
                      setOpenVerify(false);
                      localStorage.setItem("mfa", String(true));
                    }
                  }}
                >
                  Annuler
                </button>
                <button
                  className="text-white bg-gradient-to-r from-light-lavender via-light-lavender to-dark-lavender hover:bg-gradient-to-br font-medium rounded-lg 2xl:text-lg text-base  px-5 py-2.5 text-center me-2 uppercase  shadow hover:shadow-lg outline-none  ease-linear transition-all duration-150"
                  type="button"
                  onClick={() => {
                    if (openVerify === true) {
                      handleDesactivateMfa();
                    } else {
                      handleActivateMfa();
                    }
                  }}
                >
                  Ok
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
      </>
    )
  );
};

export default Mfa;
