import React from "react";
import Cookies from "js-cookie";
import { Link } from "react-router-dom";
import { useConnexionStore } from "../../stores/Layout/connexion";

const CookieConsentPopup: React.FC = () => {
  const { displayPopUpCookie, setDisplayPopUpCookie } = useConnexionStore();

  const acceptCookies = () => {
    Cookies.set("consentementCookies", "accepté", { expires: 365 });
    setDisplayPopUpCookie(false);
  };

  return displayPopUpCookie ? (
    <>
      <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
        <div className="relative w-auto md:w-4/12 my-6 mx-auto max-w-4xl">
          {/*content*/}
          <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
            {/*header*/}
            <div className="flex items-start justify-center p-4 border-b border-solid border-slate-200 rounded-t">
              <h3 className="text-xl 2xl:text-3xl font-semibold text-center text-grey-trio font-K2D">
                Autorisation des cookies ?
              </h3>
            </div>
            {/*body*/}
            <div className="text-base 2xl:text-xl p-5 text-grey-trio font-BarlowCondensed">
              <div className="flex flex-col justify-start items-center ">
                Nous utilisons des cookies pour vous garantir la meilleure
                expérience sur notre site web. En acceptant, vous consentez à
                notre utilisation des cookies de la plateforme Nexum. Voici le
                liens vers notre politique de confidentialité:{" "}
                <Link
                  to="/cookiesnotice"
                  className="text-blue-dca underline my-2"
                >
                  Politique d'utilisation des cookies sur Nexum
                </Link>
              </div>
            </div>
            {/*footer*/}
            <div className="flex items-center justify-end 2xl:p-4 p-2 border-t border-solid border-slate-200 rounded-b font-BarlowCondensed">
              <button
                className="text-white bg-gradient-to-r from-light-lavender via-light-lavender to-dark-lavender hover:bg-gradient-to-br font-medium rounded-lg 2xl:text-lg text-base  px-5 py-2.5 text-center me-2 uppercase  shadow hover:shadow-lg outline-none  ease-linear transition-all duration-150"
                type="button"
                onClick={acceptCookies}
              >
                Accepter
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
    </>
  ) : null;
};

export default CookieConsentPopup;
