import { useNotifsStore } from "../../../../stores/Utils/notifs";
import {
  dateFormat,
  hourFormat,
} from "../../../../services/Utils/functionService";
import { NavLink } from "react-router-dom";
import { usePartnerRequestsStore2 } from "../../../../stores/Cac/Partner/partnerRequests";
import { useTokenContext } from "../../../../context/tokenContext";

const Notifs = () => {
  const { showNotifs, setShowNotifs, notifsDetails, notifsDoc } =
    useNotifsStore();
  const { setOpenDetails, setRequestId } = usePartnerRequestsStore2();
  const { utp, utc } = useTokenContext();

  return (
    <div id="modal-Notifs">
      {showNotifs && (
        <div
          className={`absolute top-44 left-14 z-40 bg-white shadow-lg mt-2 p-4 rounded-md w-[260px] md:w-[350px] lg:w-[420px] h-[300px]`}
        >
          <p className="text-center font-BarlowCondensed text-lg w-full bg-yellow-trio rounded-md sticky top-0 z-10">
            {showNotifs === "Notifications" ? "Notifications" : "Documents"}
          </p>
          {notifsDetails.length === 0 && showNotifs === "Notifications" && (
            <p className=" text-center font-BarlowCondensed italic text-grey-trio ">
              Vous n'avez pas reçu de notifications
            </p>
          )}
          {notifsDoc.length === 0 && showNotifs === "Documents" && (
            <p className=" text-center font-BarlowCondensed italic text-grey-trio ">
              Vous n'avez pas reçu de documents
            </p>
          )}
          {showNotifs === "Notifications" ? (
            <div
              className={` bg-white  h-[85%] mt-1 ${
                notifsDetails.length > 3 ? "overflow-y-scroll" : ""
              }`}
            >
              {notifsDetails?.map((notif) => (
                <button
                  key={notif.id}
                  className="border-b border-gray-300 mb-2 pb-2 flex flex-col justify-start items-start w-full hover:bg-light-lavender hover:bg-opacity-20 hover:rounded-md "
                  onClick={() => {
                    if (showNotifs === "Notifications") {
                      setShowNotifs("");
                    } else {
                      setRequestId(notif?.idDocRequest);
                      setOpenDetails(true);
                    }
                  }}
                >
                  <NavLink
                    key={notif.id}
                    to={
                      utp === 1
                        ? `/partner/${notif.idPartner}/customers/${notif.idCustomer}/missions/${notif.idMission}/requests`
                        : utc === 1
                        ? `/customer/${notif.idCustomer}/missions/${notif.idMission}/requests`
                        : "#"
                    }
                  >
                    <p className="text-xs text-gray-500 text-start py-1">
                      {dateFormat(notif?.createdAt) +
                        " " +
                        hourFormat(notif?.createdAt)}
                    </p>
                    <p className="text-sm text-start">{notif?.text}</p>
                  </NavLink>
                </button>
              ))}
            </div>
          ) : (
            <div
              className={`bg-white h-[85%] mt-1 ${
                notifsDoc.length > 3 ? "overflow-y-scroll" : ""
              }`}
            >
              {notifsDoc?.map((notif) => (
                <button
                  key={notif.id}
                  className="border-b border-gray-300 mb-2 pb-2 flex flex-col justify-start items-start w-full hover:bg-light-lavender hover:bg-opacity-20 hover:rounded-md "
                  onClick={() => {
                    if (showNotifs === "Documents") {
                      setShowNotifs("");
                    } else {
                      setRequestId(notif?.idDocRequest);
                      setOpenDetails(true);
                    }
                  }}
                >
                  <NavLink
                    key={notif.id}
                    to={
                      utp === 1
                        ? `/partner/${notif.idPartner}/customers/${notif.idCustomer}/missions/${notif.idMission}/requests`
                        : utc === 1
                        ? `/customer/${notif.idCustomer}/missions/${notif.idMission}/requests`
                        : "#"
                    }
                  >
                    <p className="text-xs text-gray-500 text-start py-1">
                      {dateFormat(notif?.createdAt) +
                        " " +
                        hourFormat(notif?.createdAt)}
                    </p>
                    <p className="text-sm text-start">{notif?.text}</p>
                  </NavLink>
                </button>
              ))}
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default Notifs;
