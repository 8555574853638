import { useNavigate } from "react-router-dom";
import { useTokenContext } from "../../context/tokenContext";
import { deleteProfile } from "../../services/Api/userService";
import { showToastSuccess } from "../../services/Toasts/toasts";
import { logout } from "../../services/Api/loginService";
import { leaveRoom } from "../../services/Utils/socketIo";
import Cookies from "js-cookie";

const DeleteProfil = ({ closeModal } : any) => {
    const {
        userId,
        setUserId,
        setToken,
        setFirstname,
        setLastname,
        setEmailUser,
        setRoleId,
        setMfa,
        setUtc,
        setUtp,
        setIsAdmin,
        setIsSuperAdmin,
      } = useTokenContext();
      const navigate = useNavigate();

  const handleDeleteProfil = () => {
    deleteProfile()
    .then(() => {
        showToastSuccess("Votre compte a bien été supprimé")
        logout()
        .then(() => {
            setToken(0);
            setFirstname("");
            setLastname("");
            setEmailUser("");
            setRoleId(0);
            setIsAdmin(0);
            setUtc(0);
            setUtp(0);
            setMfa(false);
            setUserId(0);
            setIsSuperAdmin(0);
            leaveRoom(userId);
            Cookies.remove("connect.sid", { path: "/" });
            localStorage.clear();
            navigate("/");
        })
    })
    .catch((err) => console.log(err))
  }
  
  return (
    <>
    <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none min-w-full">
      <div className="relative w-auto md:w-1/4 my-6 mx-auto max-w-3xl min-w-[30%]">
        {/*content*/}
        <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
          {/*header*/}
          <div className="flex items-center justify-center p-5 border-b border-solid border-slate-200 rounded-t">
            <h3 className="text-base md:text-3xl font-semibold text-center text-gray-700 font-K2D ">
              Suppression de votre compte
            </h3>
          </div>
          {/*body*/}
          <div className="text-sm md:text-xl text-center p-5 text-gray-700 font-BarlowCondensed">
            La suppresion entrainera la perte totale de vos données. <br/>
            Etes-vous sûr de bien vouloir supprimer votre compte ?
          </div>
          {/*footer*/}
          <div className="flex items-center justify-end p-4 border-t border-solid border-slate-200 rounded-b font-BarlowCondensed">
            <button
              className="text-red-300 background-transparent hover:text-red-500 font-bold uppercase px-6 py-2 2xl:text-lg text-base  outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
              type="button"
              onClick={() => closeModal()}
            >
              Annuler
            </button>
            <button
              className="text-white bg-gradient-to-r from-light-lavender via-light-lavender to-dark-lavender hover:bg-gradient-to-br font-medium rounded-lg 2xl:text-lg text-base  px-5 py-2.5 text-center me-2 uppercase  shadow hover:shadow-lg outline-none  ease-linear transition-all duration-150"
              type="button"
              onClick={() => {
                handleDeleteProfil();
                closeModal();
              }}
            >
              Supprimer
            </button>
          </div>
        </div>
      </div>
    </div>
    <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
  </>
  )
}

export default DeleteProfil
