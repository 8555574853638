import { create } from "zustand";

type createAccount = {
    firstName: string;
    setFirstName: (firstname: string) => void;

    lastName: string;
    setLastName: (lastName: string) => void;

    email: string;
    setEmail: (email: string) => void;

    phone: string;
    setPhone: (phone: string) => void;

    password: string;
    setPassword: (password: string) => void;

    verifyPassword: string;
    setVerifyPassword: (verifyPassword: string) => void;

    title : string;
    setTitle: (title: string) => void;

    passwordInputType: string;
    setPasswordInputType: (passwordInputType: string) => void;

    acceptedCgu: boolean;
    setAcceptedCgu: (acceptedCgu: boolean) => void;

    reset: () => void;
}

export const useCreateAccount = create<createAccount>((set) => ({ 
    firstName: "",
    setFirstName: (firstName) => set({firstName}),

    lastName:"",
    setLastName: (lastName) => set({lastName}),

    email: "",
    setEmail: (email) => set({email}),

    phone: "",
    setPhone: (phone) => set({phone}),

    password: "",
    setPassword: (password) => set({password}),

    verifyPassword: "",
    setVerifyPassword: (verifyPassword) =>  set({verifyPassword}),

    title : "",
    setTitle: (title) => set({title}),

    passwordInputType: "password",
    setPasswordInputType: (passwordInputType) => set({passwordInputType}),

    acceptedCgu: false,
    setAcceptedCgu: (acceptedCgu) => set({acceptedCgu}),

    reset: () => ({
        firstName: "",
        lastName: "",
        email: "",
        phone: "",
        password: "",
        verifyPassword: "",
        acceptedCGU: false,
        title: "",
        passwordInputType: "password"
    })
}))