import { NavLink } from "react-router-dom";

const Footer = () => {
  return (
    <footer className="fixed bottom-0 w-screen h-auto flex items-center justify-center bg-white ">
      <div className="grid col-start-1 col end-2 sm:col-start-2 sm:col-end-3 2xl:mt-5 font-BarlowCondensed mb-1">
        <div className="flex flex-col items-center justify-end ">
          <div className="flex text-xs md:text-sm text-nxm-darkGray">
            <NavLink to="/legalnotice" className="underline">
              Mentions légales
            </NavLink>
            <p className="px-1">-</p>
            <NavLink to="/cookiesnotice" className="underline">
              {" "}
              Cookies
            </NavLink>
            <p className="px-1">-</p>
            <NavLink to="/cgu" className="underline">
              CGU
            </NavLink>
          </div>
          <p className="font-extralight text-xs text-center pt-1 pb-1">
            © 2024 by Trionode.
          </p>
        </div>
      </div>
    </footer>
  );
};
export default Footer;
