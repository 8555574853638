import { check2fa } from "../../services/Api/loginService";
import { useTokenContext } from "../../context/tokenContext";
import { useConnexionStore } from "../../stores/Layout/connexion";
import { showToastError } from "../../services/Toasts/toasts";
import { ToastContainer } from "react-toastify";
import { redirection } from "../../services/Utils/functionService";
import { useNavigate } from "react-router-dom";

const Modal2fa = () => {
  const {
    code2fa,
    setCode2fa,
    email,
    showModal2fa,
    setShowModal2fa,
    validate2WeeksWithout2fa,
    setValidate2WeeksWithout2fa,
  } = useConnexionStore();

  const navigate = useNavigate();
  const {
    setFirstname,
    setLastname,
    setEmailUser,
    setRoleId,
    setUserId,
    setUtp,
    setUtc,
    setIsAdmin,
    setIsSuperAdmin,
    setToken,
    setMfa,
  } = useTokenContext();

  const handleCheck2fa = async (e: any) => {
    e.preventDefault();
    try {
      await check2fa(code2fa, email, validate2WeeksWithout2fa)
        .then((data) => {
          setToken(1);
          setShowModal2fa(false);
          setFirstname(data?.firstName);
          setLastname(data?.lastName);
          setEmailUser(data?.eMail);
          setRoleId(data?.roleId);
          setUserId(data?.id);
          setUtp(data?.utp);
          setIsAdmin(data?.isAdmin);
          setIsSuperAdmin(data?.isSuperAdmin);
          setUtc(data?.utc);
          setMfa(data?.mfa);
          redirection(location, navigate, data?.isSuperAdmin);
        })
        .catch((err) => {
          console.log(err);
          showToastError("Code 2FA incorrect");
        });
    } catch (err) {
      console.log(err);
    }
  };

  return showModal2fa ? (
    <>
      <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none min-w-full">
        <div className="relative w-auto md:w-4/12 my-6 mx-auto max-w-4xl">
          {/*content*/}
          <ToastContainer position="top-center" />
          <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
            {/*header*/}
            <div className="w-full p-4 border-b border-solid border-slate-200 rounded-t">
              <h3 className="text-xl 2xl:text-3xl font-semibold text-center text-nxm-darkGray font-K2D">
                Sécurité
              </h3>
            </div>
            {/*body*/}
            <div className="w-full py-5 text-nxm-darkGray flex flex-col items-start justify-center">
              <div className="w-full bg-light-lavender h-20">
                <div className="w-11/12 m-auto flex flex-col justify-center items-center text-base pt-2">
                  <p>
                    {" "}
                    Veuillez saisir le code à 6 chiffres pour vous connecter
                  </p>
                  <input
                    type="text"
                    name="code"
                    id="code"
                    onChange={(e) => setCode2fa(e.target.value)}
                    className="border-[1px] rounded-md border-nxm-blueDark w-80 h-10 p-1 focus:outline-none "
                  />
                </div>
              </div>
            </div>
            {/*footer*/}
            <div className="flex items-center justify-end p-4 border-t border-solid border-slate-200 rounded-b">
              <div className="text-nxm-darkGray flex flex-col text-xl justify-center items-center mr-4 font-BarlowCondensed">
                <div className="flex justify-center items-center">
                  <label htmlFor="rememberme" className="pr-2 text-base">
                    Se souvenir de moi
                  </label>
                  <input
                    type="checkbox"
                    name="rememberme"
                    id="rememberme"
                    className="mt-1"
                    checked={validate2WeeksWithout2fa}
                    onChange={(e) =>
                      setValidate2WeeksWithout2fa(e.target.checked)
                    }
                  />
                </div>
                <p className="italic text-xs">Pour les 14 prochains jours</p>
              </div>
              <button
                className="text-white bg-gradient-to-r from-light-lavender via-light-lavender to-dark-lavender hover:bg-gradient-to-br font-medium rounded-lg 2xl:text-lg text-base  px-5 py-2.5 text-center me-2 uppercase  shadow hover:shadow-lg outline-none  ease-linear transition-all duration-150"
                type="button"
                onClick={(e) => {
                  handleCheck2fa(e);
                }}
              >
                Valider
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
    </>
  ) : null;
};

export default Modal2fa;
