import layout from "../../assets/icons/layout.svg";
import bell from "../../assets/icons/bellBlack.svg";
import setting from "../../assets/icons/settings.svg";
import sliders from "../../assets/icons/sliders.svg";
import { NavLink, useLocation, useParams } from "react-router-dom";
import { useTokenContext } from "../../context/tokenContext";
import { useNotifsStore } from "../../stores/Utils/notifs";
import Notifs from "../Cac/Partner/Utils/Notifs";
import { getNotifications } from "../../services/Api/userService";
import { useEffect } from "react";
import { changeFavicon } from "../../services/Utils/functionService";
import { useClickOutside } from "../../hooks/useClickOutside";
import { useSettingsStore } from "../../stores/Utils/settings";
import Settings from "../Cac/Partner/Utils/Settings";
import "../../css/sideBar.css";
import file from "../../assets/icons/file-text.svg";
import { NotifsDetailsInt } from "../../interfaces/notifs";
import { readNotification } from "../../services/Api/notifService";

const SideBar = () => {
  const { utp, roleId } = useTokenContext();
  const { idPartner } = useParams();
  const { setShowSettings, showSettings } = useSettingsStore();
  const location = useLocation();
  const {
    notifs,
    setNotifs,
    setShowNotifs,
    setNotifsDetails,
    setNotifsDoc,
    unreadNotifCount,
    setUnreadNotifCount,
    unreadNotifCountDoc,
    setUnreadNotifCountDoc,
  } = useNotifsStore();

  const profil = location.pathname.includes("profil");

  useEffect(() => {
    const filterNotifDoc = notifs.filter(
      (el) =>
        (el.idCategoryNotification === 2 && el.read === false) ||
        (el.idCategoryNotification === 2 && el.read === null)
    );
    const filterNotifDetails = notifs.filter(
      (el) =>
        (el.idCategoryNotification === 1 && el.read === false) ||
        (el.idCategoryNotification === 1 && el.read === null)
    );

    setUnreadNotifCountDoc(filterNotifDoc.length);
    setUnreadNotifCount(filterNotifDetails.length);

    if (unreadNotifCount >= 1 || unreadNotifCountDoc >= 1) {
      changeFavicon("/src/assets/logos/NexumX.png");
    } else {
      changeFavicon("/src/assets/logos/NexumX.png");
    }
  }, [notifs]);

  const toggleDetails = () => {
    getNotifications()
      .then((response) => {
        setNotifs(response);
        const filteredNotifsDoc = response.filter(
          (el: NotifsDetailsInt) => el.idCategoryNotification === 2
        );
        setNotifsDoc(filteredNotifsDoc);
        const filteredNotifsDetails = response.filter(
          (el: NotifsDetailsInt) => el.idCategoryNotification === 1
        );
        setNotifsDetails(filteredNotifsDetails);
      })
      .catch((error) => console.log(error));
  };

  useClickOutside([setShowNotifs], "close-modal");

  const handleReadNotif = (idCategory: number) => {
    readNotification(idCategory)
      .then(() => {
        getNotifications()
          .then(() => toggleDetails())
          .catch((error) => console.log(error));
      })
      .catch((error) => console.log(error));
  };

  return (
    <div className="w-14 h-screen border-r-[1px] border-light-lavender shadow-xl flex flex-col justify-start items-center bg-light-lavender z-50">
      <ul className="flex flex-col justify-start items-start font-K2D mt-5">
        <li className="mt-6 list-none">
          <NavLink
            to="/dashboard"
            className="mt-10	flex justify-start items-center"
          >
            <img src={layout} alt="dashboard" className="w-6 h-6" />
          </NavLink>
        </li>
        {roleId === 3 && !profil && (
          <li className="list-none">
            <NavLink
              to={`/partner/${idPartner}/admin/users/partner`}
              className="mt-10	hidden lg:flex justify-start items-center"
            >
              <img
                src={sliders}
                alt="ADMIN"
                className="hidden lg:flex w-6 h-6"
              />
            </NavLink>
          </li>
        )}
        {utp === 1 && !profil && (
          <li className="mt-10 list-none" id="close-modal-2">
            <button
              className="relative flex justify-center items-center "
              onClick={() => {
                setShowNotifs("Documents");
                handleReadNotif(2);
              }}
            >
              <img src={file} alt="documents" className="w-6 h-6 " />
              {unreadNotifCountDoc > 0 && (
                <div className="flex justify-center items-center">
                  <span className="absolute top-1 w-4 h-4 bg-red-500 rounded-full text-white text-xs flex items-center justify-center">
                    {unreadNotifCountDoc}
                  </span>
                </div>
              )}
            </button>
          </li>
        )}
        <li className="mt-10 list-none	" id="close-modal">
          <button
            className="relative flex justify-center items-center "
            onClick={() => {
              setShowNotifs("Notifications");
              handleReadNotif(1);
            }}
          >
            <img src={bell} alt="notifications cloche" className="w-6 h-6 " />
            {unreadNotifCount > 0 && (
              <div className="flex justify-center items-center">
                <span className="absolute top-1 w-4 h-4 bg-red-500 rounded-full text-white text-xs flex items-center justify-center">
                  {unreadNotifCount}
                </span>
              </div>
            )}
          </button>
          <Notifs />
        </li>
        <li className="mt-10 list-none	">
          <button
            className="relative flex justify-start items-center"
            onClick={() => {
              setShowSettings(!showSettings);
            }}
          >
            <img src={setting} alt="parametre" className="w-6 h-6" />
          </button>
          <Settings />
        </li>
      </ul>
    </div>
  );
};

export default SideBar;
