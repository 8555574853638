import { create } from "zustand";

type PartnerState = {
    usersPartner: any[];
    setUsersPartner: (users: any[]) => void;

    usersCustomer: any[];
    setUsersCustomer: (users: any[]) => void;

    editingUserId: any;
    setEditingUserId: (userId: any) => void;

    idRole: number;
    setIdRole: (idRole: number) => void;

    selectedIdRole: number;
    setSelectedIdRole: (selectedIdRole: number) => void;

    email: string;
    setEmail: (email: string) => void;

    roles: any[];
    setRoles: (roles: any[]) => void;

    userToUpdate : any;
    setUserToUpdate: (userToUpdate: any) => void;
}

export const usePartnerStore = create<PartnerState>((set) => ({
    usersPartner: [],
    setUsersPartner: (users) => set({ usersPartner: users }),

    usersCustomer: [],
    setUsersCustomer: (users) => set({ usersCustomer: users }),

    editingUserId: null,
    setEditingUserId: (userId) => set({ editingUserId: userId }),

    idRole: 0,
    setIdRole: (idRole) => set({ idRole }), 

    selectedIdRole: 1,
    setSelectedIdRole: (selectedIdRole) => set({ selectedIdRole }),

    email: "",
    setEmail: (email) => set({ email }),

    roles: [],
    setRoles: (roles) => set({ roles }),

    userToUpdate: {},
    setUserToUpdate: (userToUpdate) => set({userToUpdate}),
}));