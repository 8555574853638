import { useNavigate, useParams } from "react-router-dom";
import {
  dateFormat,
  splitMissionName,
} from "../../../../services/Utils/functionService";
import { useCustomerMissionStore } from "../../../../stores/Cac/Customer/customerMission";
import useSorting from "../../../../hooks/useSorting";
import Down from "../../../../assets/icons/chevron-down.svg";

const CustomerMissionTable = () => {
  // Store
  const { missionsClient, showFilterCategories, setShowFilterCategories, setIsRenewable } =
    useCustomerMissionStore();

  // Hooks
  const navigate = useNavigate();
  const [sortedData, handleSort] = useSorting(missionsClient);
  const { idCustomer } = useParams();

  return (
    <div className="w-full h-full overflow-y-scroll">
      <div className="w-full m-auto border-b-[1px] p-3 grid rounded-tl-md lg:grid-cols-6 xl:text-xl lg:text-base sticky top-0 z-10 font-BarlowCondensed border-zinc-200 text-grey-trio bg-white">
        <div className="flex col-start-1 col-end-2 justify-start items-center relative font-bold">
          Service
          <button
            onClick={() => setShowFilterCategories(!showFilterCategories)}
            className="hidden lg:flex"
          >
            <img src={Down} alt="down" className="p-1" />
          </button>
        </div>
        <div className="hidden lg:flex col-start-2 col-end-3 justify-start items-center font-bold">
          Nom de la mission
          <button onClick={() => handleSort("name")} className="hidden lg:flex">
            <img src={Down} alt="down" className="p-1" />
          </button>
        </div>
        <div className="flex col-start-3 col-end-4 justify-start items-center font-bold">
          Responsable
          <button
            onClick={() => handleSort("fullName")}
            className="hidden lg:flex"
          >
            <img src={Down} alt="down" className="p-1" />
          </button>
        </div>
        <div className="flex col-start-4 col-end-5 justify-start items-center font-bold">
          Fin de mission
          <button
            onClick={() => handleSort("dueDate")}
            className="hidden lg:flex"
          >
            <img src={Down} alt="down" className="p-1" />
          </button>
        </div>
        <div className="hidden lg:flex col-start-5 col-end-7 mr-16 justify-start items-center font-bold">
          Statut des requêtes
        </div>
      </div>

      <div className="w-full h-[92%] text-md xl:text-lg font-BarlowCondensed font-light">
        {sortedData?.map((mission) => (
          <div
            key={mission?.id}
            className={`w-full m-auto grid lg:grid-cols-6 p-3 hover:bg-light-lavender hover:bg-opacity-20 transition-all duration-150 `}
          >
            <button
              type="button"
              className="grid lg:grid-cols-6 col-start-1 col-end-7 "
              onClick={() => {
                setIsRenewable(mission?.isRenewable);
                navigate(
                  `/customer/${idCustomer}/missions/${mission?.id}/requests`
                );
              }}
            >
              <div className="col-start-1 col-end-2 text-start ">
                {mission?.category} {mission?.nameMissionPeriod}
              </div>
              <div className="hidden lg:flex col-start-2 col-end-3 text-start ">
                {splitMissionName(mission?.name)}
              </div>
              <div className="col-start-3 col-end-4 text-start">
                {mission?.fullName}
              </div>
              <div className="col-start-4 col-end-5 text-start ">
                {dateFormat(mission?.dueDate)}
              </div>
              <div className="col-start-5 col-end-8 text-start">
                <div className="w-full sm:grid sm:grid-cols-4 sm:gap-x-2 lg:w-8/12">
                  <div className="flex justify-start items-center">
                    <div className="w-6 h-3 border-[1px] rounded-md border-filter-yellow bg-filter-yellow mr-2 min-w-[24px]"></div>
                    <p className="pl-1">
                      {mission?.DocRequests[0] &&
                      Object.keys(mission.DocRequests[0])[0] === "En Attente"
                        ? mission.DocRequests[0]["En Attente"]
                        : 0}
                    </p>
                  </div>
                  <div className="flex justify-start items-center">
                    <div className="w-6 h-3 border-[1px] rounded-md border-filter-orange bg-filter-orange mr-2 min-w-[24px]"></div>
                    <p className="pl-1">
                      {mission?.DocRequests[3] &&
                      Object.keys(mission.DocRequests[3])[0] === "En retard"
                        ? mission.DocRequests[3]["En retard"]
                        : 0}
                    </p>
                  </div>
                  <div className="flex justify-start items-center">
                    <div className="w-6 h-3 border-[1px] rounded-md border-filter-blue bg-filter-blue mr-2 min-w-[24px]"></div>
                    <p className="pl-1">
                      {mission?.DocRequests[1] &&
                      Object.keys(mission.DocRequests[1])[0] === "Délivrée"
                        ? mission.DocRequests[1]["Délivrée"]
                        : 0}
                    </p>
                  </div>
                  <div className="flex justify-start items-center">
                    <div className="w-6 h-3 border-[1px] rounded-md border-filter-green bg-filter-green mr-2 min-w-[24px]"></div>
                    <p className="pl-1">
                      {mission?.DocRequests[2] &&
                      Object.keys(mission.DocRequests[2])[0] === "Accepté"
                        ? mission.DocRequests[2]["Accepté"]
                        : 0}
                    </p>
                  </div>
                </div>
              </div>
            </button>
          </div>
        ))}
      </div>
    </div>
  );
};

export default CustomerMissionTable;
