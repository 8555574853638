import { usePartnerMissionStore } from "../../../../stores/Cac/Partner/partnerMission";
import { usePartnerWorkingFolderStore } from "../../../../stores/Cac/Partner/partnerWorkingFolder";
import { useUtilsStore } from "../../../../stores/Utils/selects";

const SelectYear = () => {
  const { years, selectedYear, setSelectedYear } = useUtilsStore();
  const { choiceModalWF, dataWorkingFolder, setDataWorkingFolder } =
    usePartnerWorkingFolderStore();
  const { choiceModal, dataMission, setDataMission } = usePartnerMissionStore();

  return (
    <select
      name="years"
      id="years"
      onChange={(e) => {
        setSelectedYear(e.target.value);
        {
          choiceModal !== ""
            ? setDataMission({
                ...dataMission,
                dateExercice: e.target.value,
              })
            : choiceModalWF === "create"
            ? setDataWorkingFolder({
                ...dataWorkingFolder,
                dateExercice: e.target.value,
              })
            : null;
        }
      }}
      value={selectedYear}
      className="w-1/4 h-10 p-1 border-[1px] border-gray-300 rounded-md mr-2 shadow-md"
    >
      {" "}
      {selectedYear === "" && <option value="">Année fiscale</option>}
      {years?.map((year) => (
        <option value={year} key={year}>
          {year}
        </option>
      ))}
    </select>
  );
};

export default SelectYear;
