import { useConnexionStore } from "../../stores/Layout/connexion";
import { verifyMailIfExist } from "../../services/Api/userService";
import { useCreateAccount } from "../../stores/Layout/createAccount";
import { useState } from "react";

const VerifyEmailRegister = () => {
  // Store
  const { showModalVerifyEmail, setShowModalVerifyEmail } = useConnexionStore();
  const { email, setEmail } = useCreateAccount();
  const [info, setInfo] = useState<string>("");

  const handleKeyDown = (event: React.KeyboardEvent) => {
    if (event.key === "Enter") {
      handleSubmit(event);
    }
  };

  const handleSubmit = async (event: any) => {
    event.preventDefault();

    verifyMailIfExist(email)
      .then((data) => {
        if (data.exist === true && data.associated === false) {
          setInfo(
            "✅ Un mail de création de compte viens de vous être envoyer."
          );
        } else if (data.exist === true && data.associated === true) {
          setInfo(
            "Un compte associé à ces informations existe déjà. Veuillez tenter de vous connecter."
          );
        } else if (data.exist === false && data.associated === false) {
          setInfo(
            "❌ Vous n'avez pas été invité a rejoindre Nexum. Pour tous renseignements supplémentaires merci de contacter le service informatique de Trionode."
          );
        } else {
          setInfo("");
        }
      })
      .catch((error) => console.log(error));
  };

  return (
    <>
      {showModalVerifyEmail ? (
        <>
          <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none text-grey-trio">
            <div className="relative w-auto md:w-4/12 my-6 mx-auto max-w-3xl xl:min-w-[25%]">
              {/*content*/}
              <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                {/*header*/}
                <div className="flex items-center justify-center p-4  border-b border-solid border-slate-200 rounded-t">
                  <h3 className="text-xl 2xl:text-3xl font-semibold text-center text-grey-trio font-K2D">
                    Vérification de votre adresse mail
                  </h3>
                </div>
                {/*body*/}
                <div className="w-full py-5 text-grey-trio flex flex-col items-start justify-center">
                  <p className="w-10/12 m-auto mb-5 text-center">{info}</p>
                  <div className="w-full bg-light-lavender h-20">
                    <div className="w-11/12 m-auto flex flex-col justify-center items-center text-base pt-1">
                      <label htmlFor="email" className="leading-relaxed">
                        Veuillez inscrire votre adresse mail :
                      </label>
                      <input
                        type="email"
                        className="border-[1px] rounded-md border-dark-lavender w-80 h-10 p-1 focus:outline-none "
                        pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$"
                        onChange={(e) => setEmail(e.target.value)}
                        minLength={10}
                        maxLength={200}
                        name="email"
                        id="email"
                        placeholder="votre-email@exemple.com"
                        onKeyDown={handleKeyDown}
                        required
                      />
                    </div>
                  </div>
                </div>
                {/*footer*/}
                <div className="flex items-center justify-end p-2 border-t border-solid border-slate-200 rounded-b font-BarlowCondensed">
                  <button
                    className="text-red-300 background-transparent hover:text-red-500 font-bold uppercase px-6 py-2 2xl:text-lg text-base  outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
                    type="button"
                    onClick={() => setShowModalVerifyEmail(false)}
                  >
                    ANNULER
                  </button>
                  <button
                    className="text-white bg-gradient-to-r from-light-lavender via-light-lavender to-dark-lavender hover:bg-gradient-to-br font-medium rounded-lg 2xl:text-lg text-base  px-5 py-2.5 text-center me-2 uppercase  shadow hover:shadow-lg outline-none  ease-linear transition-all duration-150"
                    type="submit"
                    onClick={(
                      event: React.MouseEvent<HTMLButtonElement, MouseEvent>
                    ) => {
                      handleSubmit(event);
                    }}
                  >
                    ENVOYER
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </>
      ) : null}
    </>
  );
};

export default VerifyEmailRegister;
