import { NavLink } from "react-router-dom";
import { useEffect } from "react";
import {
  getInfoDashboardNbOfRequest,
  getNotifications,
  getUserDashboardDataCount,
  userClientAssignment,
  userPartnerAssignment,
} from "../../services/Api/userService";
import { useDashboardStore } from "../../stores/Layout/dashboard";
import { useTokenContext } from "../../context/tokenContext";
import "../../css/animations.css";
import SideBar from "../../components/Layout/SideBar";
import "tailwindcss/tailwind.css";
import { dateFormat, hourFormat } from "../../services/Utils/functionService";
import { ToastContainer } from "react-toastify";
import "../../css/carousel.css";
import CarouselDashboard from "../../components/Layout/CarouselDashboard";
import Header from "../../components/Layout/Header";
import Footer from "../../components/Layout/Footer";

const Dashboard = () => {
  const {
    setCardsPartner,
    setCardsCustomer,
    dataCount,
    setDataCount,
    lastNotifications,
    setLastNotifications,
    requestsInfo,
    setRequestsInfo,
  } = useDashboardStore();
  const { firstname, utp, utc } = useTokenContext();

  // Ajout des assignations aux cartes
  useEffect(() => {
    userClientAssignment()
      .then((response) => setCardsCustomer(response))
      .catch((error) => console.log(error));

    userPartnerAssignment()
      .then((response) => setCardsPartner(response))
      .catch((error) => console.log(error));
  }, []);

  useEffect(() => {
    getUserDashboardDataCount()
      .then((data) => setDataCount(data))
      .catch((error) => console.log(error));

    getNotifications()
      .then((response) => {
        const filteredLastNotifs = response
          .sort(
            (a: any, b: any) =>
              new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
          )
          .slice(0, 5);
        setLastNotifications(filteredLastNotifs);
      })
      .catch((error) => console.log(error));

    if (Notification.permission === "default") {
      Notification.requestPermission().then((permission) => {
        if (permission === "granted") {
          console.log("Permission accordée pour recevoir des notifications");
        } else {
          console.log("Permission refusée pour recevoir des notifications");
        }
      });
    } else if (Notification.permission === "granted") {
      console.log("Permission déjà accordée");
    } else {
      console.log("Permission déjà refusée");
    }

    getInfoDashboardNbOfRequest()
      .then((response) => setRequestsInfo(response))
      .catch((error) => console.log(error));
  }, []);

  return (
    <div className="w-screen h-screen bg-white flex flex-col justify-start items-start overflow-hidden">
      <Header />
      <div className="w-full flex justify-start items-start">
        <SideBar />

        <ToastContainer position="top-center" />
        <div className="w-9/12 m-auto h-auto flex flex-col justify-between items-center mt-5 2xl:mt-20">
          <div className="w-full rounded-md h-auto flex justify-between items-center bg-white shadow-2xl text-nxm-darkGray">
            <div className="flex flex-col justify-start items-start pl-5 py-4 font-K2D ">
              <p className="flex justify-start items-start text-lg xl:text-xl">
                Bonjour {firstname}
              </p>
              <p className="font-thin">Tableau de bord</p>
            </div>
            <div className="hidden lg:flex font-K2D">
              <div className="flex justify-end items-end border-r-2 my-4 xl:mx-4 mr-2">
                <p className="mr-4">Missions ouvertes</p>
                <p className="font-bold mr-4">
                  {dataCount?.totalOpenMissionsCount}
                </p>
              </div>
              <div className="flex justify-end items-end border-r-2 my-4 xl:mx-4 mr-2">
                <p className="mr-4">Requêtes en attente</p>
                <p className="font-bold mr-4">
                  {dataCount?.totalDocRequestsWaitingCount}
                </p>
              </div>
              <div className="flex justify-end items-end my-4 xl:mx-4 mr-2">
                <p className="mr-4">Requêtes délivrées</p>
                <p className="font-bold mr-4">
                  {dataCount?.totalDocRequestsAcceptedCount}
                </p>
              </div>
            </div>
          </div>
          <div className="w-full mt-2 mb-2 flex justify-center items-center ml-2 ag-courses_box 3xl:my-10 2xl:my-5 my-2">
            <CarouselDashboard />
          </div>
          <div className="md:flex flex-col justify-start items-center lg:grid grid-cols-3 w-full h-full 3xl:max-h-72 max-h-44 gap-4">
            <div className="hidden col-start-1 col-end-3 lg:flex flex-col justify-start items-center bg-white rounded-lg shadow-2xl h-full mb-5 md:mb-0">
              <p className="text-center font-K2D text-xl py-4 font-bold text-nxm-darkGray">
                Expiration des requêtes dans 2 jours
              </p>
              <div className="w-11/12 flex justify-start items-start border-[1px] bg-white rounded-lg shadow-xl overflow-y-scroll h-full mb-10">
                <table className="w-full ">
                  <thead className="w-full bg-light-lavender text-grey-trio h-8">
                    <tr className="border-b-[1px]">
                      <th className="text-left pl-2">Partenaire</th>
                      <th className="text-left">Dossier</th>
                      <th className="text-left">Mission</th>
                      <th className="text-left">Nb requêtes</th>
                    </tr>
                  </thead>
                  <tbody>
                    {requestsInfo?.length !== 0 ? (
                      requestsInfo?.map((request: any) => (
                        <tr
                          key={request.id}
                          className="border-b-[1px] h-10 hover:bg-light-lavender hover:bg-opacity-30 font-BarlowCondensed"
                        >
                          <td className="pl-2">{request?.namePartner}</td>
                          <td>{request?.nameCustomer}</td>
                          <td>{request?.nameMission}</td>
                          <td className="pl-10">{request?.docRequestCount}</td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td
                          colSpan={4}
                          className="text-center 2xl:text-lg text-sm font-thin italic pt-2"
                        >
                          Aucune requête n'expire dans 2 jours
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
            <div className="hidden lg:flex w-full h-full md:col-start-3 col-start-1 col-end-4 font-BarlowCondensed  flex-col justify-start items-start 2xl:gap-4 gap-2">
              {lastNotifications?.length > 0 ? (
                lastNotifications?.map((notif) => {
                  return (
                    <NavLink
                      key={notif.id}
                      to={
                        utp === 1
                          ? `/partner/${notif.idPartner}/customers/${notif.idCustomer}/missions/${notif.idMission}/requests`
                          : utc === 1
                          ? `/customer/${notif.idCustomer}/missions/${notif.idMission}/requests`
                          : "#"
                      }
                      className="w-full text-lg rounded-lg bg-white h-auto flex flex-col justify-start items-start shadow-2xl"
                    >
                      <div className="flex justify-start items-start bg-yellow-trio rounded-t-md h-auto  w-full">
                        <p className="text-white 2xl:text-md pt-1 2xl:pt-0 text-sm w-full text-start font-thin italic pl-2">
                          {dateFormat(notif?.createdAt)} à{" "}
                          {hourFormat(notif?.createdAt)}
                        </p>
                        <p className=" text-white 3xl:text-xl text-lg w-full font-bold">
                          {notif?.namePartner}
                        </p>
                        <></>
                      </div>
                      <p className="bg-whitefont-K2D 3xl:text-lg text-sm text-nxm-darkGray text-center hover:bg-light-lavender hover:bg-opacity-30 h-auto hover:rounded-b-lg w-full py-2">
                        {notif?.text}
                      </p>
                    </NavLink>
                  );
                })
              ) : (
                <p className="italic text-center w-full text-md md:text-lg rounded-lg bg-white h-20 pt-4">
                  Vous n'avez reçu aucune notification.
                </p>
              )}
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Dashboard;
