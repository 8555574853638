import { Link, useNavigate } from "react-router-dom";
import Logo from "../../assets/logos/logoX.png";

const CookiesNotice = () => {
  const navigate = useNavigate();
  return (
    <div className="w-screen flex flex-col items-center justify-center ">
      <img
        src={Logo}
        alt="logo nexum"
        className="w-24 h-36 py-5 cursor-pointer"
        onClick={() => navigate(-1)}
      />
      <div className="w-screen bg-light-lavender h-32 flex flex-col items-center justify-center">
        <h1 className="text-lg text-center font-extralight">
          <span className="font-bold text-3xl">
            Politique d'utilisation des cookies sur NEXUM
          </span>{" "}
          <br /> en date du 23/02/2023
        </h1>
      </div>

      <div className="w-10/12 flex flex-col items-center justify-start">
        <p className=" w-10/12 py-10 text-center">
          Au cours de votre navigation sur le site internet de TRIONODE SARL,
          des cookies sont susceptibles d’être déposés sur l’appareil
          (téléphone, tablette, ordinateur…) que vous utilisez pour y accéder.
        </p>
        <h3 className="w-10/12 text-xl text-dark-lavender font-bold">
          C’est quoi un Cookie ?{" "}
        </h3>
        <p className="w-10/12 py-10 text-justify">
          Un Cookie est un témoin de connexion, un fichier qui comportent une
          faible quantité d’informations. Les cookies sont de petits fichiers
          qui sont insérés sur l’ordinateur d’un internaute par le site web au
          moment de sa consultation. Ces fichiers enregistrent ensuite des
          informations concernant l’internaute, il peut s’agir d’informations
          nominatives le concernant. Les informations ainsi collectées peuvent
          ensuite être analysées afin de faciliter la navigation sur le site,
          proposer des publicités ciblées et analyser les habitudes de
          navigation.
        </p>
        <h3 className="w-10/12 text-xl text-dark-lavender font-bold">
          Ça sert à quoi ?{" "}
        </h3>
        <p className="w-10/12 py-8 text-justify">
          Il permet « l’enregistrement d’informations par le serveur dans un
          fichier texte situé sur l’ordinateur client […], informations que ce
          même serveur (et lui seul) peut aller relire et modifier
          ultérieurement ». <br></br> <br></br> Il existe différents types de
          cookies, par exemple : <br></br>
        </p>
        <p className="w-8/12 py-2 text-justify">
          - Les cookies techniques/fonctionnels : ils sont utilisés pour
          permettre d’identifier les services et les rubriques que l’utilisateur
          a visités sur un site, pour faciliter la navigation sur le site, pour
          permettre aux utilisateurs de bénéficier de certaines fonctionnalités
          – ils ne peuvent être désactivés car nécessaire au fonctionnement du
          site.
        </p>
        <p className="w-8/12 py-2 text-justify">
          - Les cookies de mesure d’audience et de suivi de navigation : ils
          permettent d’analyser le trafic d’un site (nombre d’affichage par
          page, durée passée sur chaque page, nombre de clics, résolution de
          l’écran, langue préférée, site visité, horodatage des pages
          visitées…), de proposer des offres promotionnelles ou des bannières
          qui apparaissent sur le site plus ou moins ciblées, pour offrir des
          fonctionnalités relatives aux réseau et médias sociaux, pour permettre
          le fonctionnement de certains services, pour mesurer les audiences de
          services. Les désactiver empêche donc toute collecte d’informations
          relatives à la navigation sur un site et donc la proposition de
          contenus éditoriaux adaptés en fonction de votre navigation
        </p>
        <p className="w-8/12 py-2 text-justify">
          - Les cookies d’applications tierces : sur certains sites, il existe
          des applications qui permettent aux utilisateurs de faire connaître ou
          de partager le contenu du site auprès d’autres personnes, et ce en
          particulier sur les réseaux. Même si l’utilisateur n’utilise pas ces
          boutons de partage ou applications, il est possible que les réseaux
          sociaux suivent sa navigation si jamais son compte ou session est
          activée sur son ordinateur à ce moment-là.
        </p>
        <p className="w-8/12 py-2 text-justify">
          - Les cookies de partenaires publicitaires : ils sont déposés par des
          sociétés autres que le site visité qui sont souvent des partenaires
          commerciaux, prestataires de publicité ciblée. Le responsable du site
          n’a pas de pouvoir d’action sur ces cookies qui sont gérés par
          d’autres sociétés. Seul l’émetteur d’un cookie peut lire ou modifier
          les informations de ce cookie. Un site accueillant des partenaires
          publicitaires est susceptible de leurs transmettre des informations en
          lien avec vos achats et produits consultés si c’est le cas.
        </p>
        <p className="w-8/12 py-2 text-justify">
          - Les cookies optionnels : ils sont utilisés pour permettre
          l’amélioration de l’expérience utilisateur et de faciliter les
          recherches de l’utilisateur en proposant des produits et offres en
          lien avec les centres d’intérêt de l’utilisateur. Ils ne sont pas
          indispensables à la navigation.
        </p>
        <h3 className="w-10/12 text-xl text-dark-lavender font-bold pt-8">
          Quels Cookies utilisent le site de TRIONODE SARL{" "}
        </h3>
        <p className="w-10/12 py-4 text-justify">
          Le site web de TRIONODE SARL utilise uniquement des cookies
          fonctionnels.Le dépôt de cookies fonctionnels ne requiert pas le
          consentement préalable des internautes
        </p>

        <h3 className="w-10/12 text-xl text-dark-lavender font-bold pt-8">
          Quels Cookies utilisent le site de TRIONODE SARL ?
        </h3>
        <p className="w-10/12 py-4 text-justify">
          Le site web de TRIONODE SARL utilise uniquement des cookies
          fonctionnels.Le dépôt de cookies fonctionnels ne requiert pas le
          consentement préalable des internautes
        </p>

        <p className="w-10/12 pt-4 text-justify font-bold">
          Pour plus d’information sur les cookies :
        </p>
        <p className="w-10/12 pb-4 text-justify">
          Pour vous guider dans la démarche de limiter vos traces sur le web,
          nous vous invitons à consulter les pages suivantes :{" "}
        </p>
        <Link
          className="font-bold cursor-pointer underline"
          to={
            "https://www.ccin.mc/fr/fiches-pratiques/bonnes-pratiques-en-matiere-de-sites-internet"
          }
        >
          -
          https://www.ccin.mc/fr/fiches-pratiques/bonnes-pratiques-en-matiere-de-sites-internet
        </Link>
        <Link
          className="font-bold mb-10 cursor-pointer underline"
          to={
            "http://www.cnil.fr/vos-droits/vos-traces/les-cookies/conseils-aux-internautes"
          }
        >
          -
          http://www.cnil.fr/vos-droits/vos-traces/les-cookies/conseils-aux-internautes
        </Link>
      </div>
    </div>
  );
};

export default CookiesNotice;
