import { useEffect } from "react";
import {
  deleteRequest,
  getDocRequest,
  handleAcceptDoc,
  handleRejectDoc,
  refuseRequest,
} from "../../../../services/Api/requestService";
import Right from "../../../../assets/icons/chevron-right.svg";
import "../../../../css/requestDetail.css";
import "tailwindcss/tailwind.css";
import PartnerDocumentTable from "./RequestDocumentTable";
import RequestDetails from "./RequestDetails";
import { DropZoneDocument } from "../Utils/DropZoneDocument";
import { usePartnerRequestDetailsStore } from "../../../../stores/Cac/Partner/partnerRequestDetails";
import { useClickOutside } from "../../../../hooks/useClickOutside";
import { usePartnerRequestsStore2 } from "../../../../stores/Cac/Partner/partnerRequests";
import {
  unwatchDocRequest,
  watchDocRequest,
} from "../../../../services/Api/userService";
import { useNotifsStore } from "../../../../stores/Utils/notifs";
import { downloadFiles } from "../../../../services/Api/documentService";
import {
  showToastError,
  showToastSuccess,
} from "../../../../services/Toasts/toasts";
import RequestChat from "./RequestChat";

const ModalDocRequestDetails = ({ openModal, closeModal }: any) => {
  // Store
  const {
    requestId,
    setRequestId,
    openDetails,
    setOpenDetails,
    updateRequest,
    setUpdateRequest,
  } = usePartnerRequestsStore2();
  const { notifs } = useNotifsStore();
  const {
    request,
    setRequest,
    setComment,
    openDocuments,
    setOpenDocuments,
    openCommentary,
    setOpenCommentary,
    setStartDate,
    loading,
    setLoading,
    activeNotification,
    setRequestRefused,
    setActiveNotification,
    setMessage,
    setMessages,
  } = usePartnerRequestDetailsStore();

  // Hooks
  useClickOutside([setOpenDetails], "modal-doc-request-details");

  useEffect(() => {
    setOpenCommentary(false);
    if (openDetails === true) {
      getDocRequest(requestId)
        .then((data) => {
          setRequest(data[0]);
          setStartDate(new Date(data[0].dueDate));
          setActiveNotification(data[0]?.watching);
        })
        .catch((error) => console.log(error));
    }
  }, [requestId, updateRequest, notifs]);

  const handleClick = async () => {
    setLoading(true);
    try {
      await downloadFiles(requestId);
      setUpdateRequest(!updateRequest);
      showToastSuccess("Les documents ont bien été téléchargés");
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const handleAcceptDocButton = async (requestId: number) => {
    await handleAcceptDoc(requestId);
    setUpdateRequest(!updateRequest);
  };

  const handleDeleteRequest = async (requestId: number) => {
    await deleteRequest(requestId);
    setOpenDetails(false);
    setUpdateRequest(!updateRequest);
    setRequestId(0);
    setRequest(null);
    showToastSuccess("La requête a bien été supprimée");
  };

  const handleRejectDocButton = async (requestId: number) => {
    await handleRejectDoc(requestId);
    setRequestRefused(true);
    setUpdateRequest(!updateRequest);
  };

  const handleRefuseRequest = (requestId: number) => {
    refuseRequest(requestId)
      .then(() => {
        setRequestRefused(true);
        setOpenDocuments("Chat");
        setUpdateRequest(!updateRequest);
        showToastSuccess("La requête a bien été refusée");
      })
      .catch((error) => {
        console.log(error);
        showToastError("Une erreur est survenue lors du refus de la requête");
      });
  };

  const handleWatchNotification = () => {
    try {
      if (activeNotification === false || activeNotification === null) {
        watchDocRequest(requestId)
          .then(() => setActiveNotification(!activeNotification))
          .catch((error) => console.log(error));
      } else {
        unwatchDocRequest(requestId)
          .then(() => setActiveNotification(!activeNotification))
          .catch((error) => console.log(error));
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (openDetails === false) {
      setRequest(null);
      setComment("");
      setStartDate(new Date());
      setActiveNotification(false);
      setRequestRefused(false);
      setMessage("");
      setMessages([]);
      setActiveNotification(false);
    }
  }, [openDetails]);

  return (
    <div
      className={`fixed right-0 top-0 h-full w-full lg:w-3/12 bg-dark-lavender2 shadow-2xl transition-all duration-300 ease-in-out transform z-50 min-w-[30%] font-BarlowCondensed ${
        openDetails ? "translate-x-0" : "translate-x-full"
      }`}
      id="modal-doc-request-details"
    >
      <div className="flex flex-col w-10/12 m-auto">
        <button
          type="button"
          className="lg:w-20 2xl:h-20 h-10 w-10 "
          onClick={() => setOpenDetails(false)}
        >
          <img src={Right} alt="chevron right" />
        </button>
        <div className="flex items-center justify-between w-full">
          <p className="text-white text-2xl font-K2D">
            Requête : {request?.fakeId}{" "}
          </p>
          <button onClick={() => handleWatchNotification()}>
            {!activeNotification ? (
              <button className="border-[1px] rounded-md bg-yellow-trio px-2 mb-2 text-grey-trio">
                Suivre par mail
              </button>
            ) : (
              <button className="border-[1px] rounded-md bg-yellow-trio px-2 mb-2 bg-opacity-60 text-grey-trio">
                Suivie
              </button>
            )}
          </button>
        </div>
        <div className="flex items-center justify-between w-full">
          <div className="flex justify-center items-center">
            <p className="text-white text-md italic font-extralight mr-2">
              {request?.State?.state}
            </p>
            <div
              className={`w-8 h-4 border-[1px] rounded-md mr-2 justify-start
                        ${
                          request?.State.state === "En Attente"
                            ? `border-filter-yellow bg-filter-yellow`
                            : null
                        }
                        ${
                          request?.State.state === "Délivrée"
                            ? `border-filter-blue bg-filter-blue`
                            : null
                        }
                        ${
                          request?.State.state === "Accepté"
                            ? `border-filter-green bg-filter-green`
                            : null
                        }
                        ${
                          request?.State.state === "En retard"
                            ? `border-filter-orange bg-filter-orange`
                            : null
                        }
                        `}
            ></div>
          </div>
          {(request?.State?.state !== "Accepté" &&
            request?.isPeriodClosed === null) ||
          request?.isPeriodClosed === false ? (
            <div className="flex justify-center items-center">
              {request?.State.state === "En Attente" ? null : (
                <button
                  type="button"
                  className="w-20 h-6 bg-red-400 rounded-md font-extralight hover:bg-filter-orange shadow text-md  text-center mr-2"
                  onClick={() => {
                    handleRefuseRequest(requestId);
                  }}
                >
                  Refuser
                </button>
              )}
              <button
                type="button"
                className="w-20 h-6 bg-filter-green rounded-md font-extralight hover:bg-green-400 shadow text-md text-center"
                onClick={() => handleAcceptDocButton(requestId)}
              >
                Accepter
              </button>
            </div>
          ) : (request?.State?.state === "Accepté" &&
              request?.isPeriodClosed === null) ||
            request?.isPeriodClosed === false ? (
            <button
              type="button"
              className="w-40 h-6 bg-filter-yellow rounded-md font-extralight shadow text-md"
              onClick={() => handleRejectDocButton(requestId)}
            >
              Réouvrir la requête
            </button>
          ) : null}
        </div>
      </div>
      <div className="w-10/12 flex justify-between pt-5 m-auto pb-2">
        <div className="flex justify-start items-center">
          <button type="button" onClick={() => setOpenDocuments("Documents")}>
            <p
              className={`text-white text-xl mr-5 font-extralight ${
                openDocuments === "Documents" ? `underline font-semibold` : ``
              }`}
            >
              Documents{" "}
            </p>
          </button>
          <button type="button" onClick={() => setOpenDocuments("Details")}>
            <p
              className={`text-white text-xl mr-5 font-extralight ${
                openDocuments === "Details" ? `underline font-semibold` : ``
              }`}
            >
              Détails{" "}
            </p>
          </button>
          <button
            type="button"
            className="relative"
            onClick={() => {
              setOpenDocuments("Chat");
              setUpdateRequest(!updateRequest);
            }}
          >
            {request?.chatRead === false ? (
              <div className="absolute top-1 left-7 rounded-full w-2 h-2 bg-red-600"></div>
            ) : (
              ""
            )}
            <p
              className={`text-white text-xl mr-5 font-extralight ${
                openDocuments === "Chat" ? `underline font-semibold` : ``
              }`}
            >
              Chat{" "}
            </p>
          </button>
        </div>
        <div className="flex justify-end items-end">
          {openDocuments === "Details" ? (
            <>
              <button
                type="button"
                className="flex justify-end pr-2"
                title="Modifier"
                onClick={() => setOpenCommentary(!openCommentary)}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="white"
                  strokeWidth="1"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  className="feather feather-edit-2"
                >
                  <path d="M17 3a2.828 2.828 0 1 1 4 4L7.5 20.5 2 22l1.5-5.5L17 3z"></path>
                </svg>
              </button>
              <button
                type="button"
                title="Supprimer"
                onClick={() => handleDeleteRequest(requestId)}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="white"
                  strokeWidth="1"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  className="feather feather-trash-2"
                >
                  <polyline points="3 6 5 6 21 6"></polyline>
                  <path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2"></path>
                  <line x1="10" y1="11" x2="10" y2="17"></line>
                  <line x1="14" y1="11" x2="14" y2="17"></line>
                </svg>
              </button>
            </>
          ) : null}
        </div>
      </div>
      <hr className="w-10/12 m-auto pt-2 " />

      {openDocuments === "Documents" && (
        <div className="h-[75%] md:h-[85%] w-full">
          <div className="w-10/12 m-auto flex justify-end ">
            <button
              className={
                !loading
                  ? "border-[1px] mb-2 rounded-md p-1 hover:bg-zinc-300 bg-white"
                  : `flex items-center justify-center w-44 pl-2 ml-2 py-2 text-sm font-medium text-gray-900 bg-white rounded-sm border hover:bg-gray-100 hover:text-filter-green focus:z-10 focus:ring-2 focus:ring-filter-green dark:text-gray-400 border-filter-green`
              }
              onClick={handleClick}
              disabled={loading}
            >
              {!loading ? (
                <p className="text-sm text-gray-500 hover:text-gray-800">
                  Télécharger tous les documents
                </p>
              ) : (
                <svg
                  aria-hidden="true"
                  role="status"
                  className="inline w-4 h-4 mr-3 text-gray-200 animate-spin dark:text-gray-600"
                  viewBox="0 0 100 101"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                    fill="currentColor"
                  />
                  <path
                    d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                    fill="#1C64F2"
                  />
                </svg>
              )}
            </button>
          </div>
          {request?.State?.state !== "Accepté" ? (
            <DropZoneDocument requestId={requestId} />
          ) : null}

          <PartnerDocumentTable openModal={openModal} closeModal={closeModal} />
        </div>
      )}
      {openDocuments === "Details" && <RequestDetails />}
      {openDocuments === "Chat" && <RequestChat />}
    </div>
  );
};

export default ModalDocRequestDetails;
