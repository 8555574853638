import { useSuperAdminCreateUserPartnerStore } from "../../../stores/superadmin/createUser";
import { useEffect } from "react";
import { getRoleList } from "../../../services/Api/roleService";
import { createUserPartner } from "../../../services/Api/superadminService";
import {
  showToastError,
  showToastSuccess,
} from "../../../services/Toasts/toasts";

const AddUser = ({ idPartner, closeModal, namePartner }: any) => {
  const {
    eMail,
    setEmail,
    selectedRole,
    setSelectedRole,
    roles,
    setRoles,
    reset,
  } = useSuperAdminCreateUserPartnerStore();

  useEffect(() => {
    getRoleList()
      .then((data) => {
        const formattedRole = data?.map((role: any) => ({
          value: role.id,
          label: role.role,
        }));
        setRoles(formattedRole);
      })
      .catch((error) => console.log(error));
  }, []);

  const handleCreateUser = () => {
    const data = {
      eMail,
      idRole: selectedRole,
      idPartner,
    };

    createUserPartner(data)
      .then(() => {
        showToastSuccess("L'utilisateur a bien été créé !");
        closeModal();
        reset();
      })
      .catch(() => showToastError("Une erreur est survenue."));
  };

  return (
    <>
      <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none ">
        <div className="relative w-auto md:w-1/4 my-6 mx-auto max-w-3xl min-w-[40%]">
          {/*content*/}
          <div className="border-0 relative flex flex-col w-full bg-white outline-none focus:outline-none rounded-t">
            {/*header*/}
            <div className="flex items-center justify-center p-4 border-b border-solid border-slate-200 rounded-t">
              <h3 className="text-xl 2xl:text-3xl font-semibold text-center font-K2D text-nxm-darkGray ">
                <p>
                  Créer un utilisateur pour{" "}
                  <span className="font-extrabold">{namePartner}</span>
                </p>
              </h3>
            </div>
            {/*body*/}
            <div className="w-10/12 m-auto flex flex-col items-center justify-center">
              <div className="flex justify-center items-center w-full py-4">
                <div className="w-1/2 mr-10">
                  <p className="text-gray-500">Email :</p>
                  <input
                    type="email"
                    className="border-b-[1px] border-nxm-darkGray w-full h-auto p-2 bg-transparent"
                    onChange={(e) => setEmail(e.target.value)}
                    minLength={2}
                    maxLength={200}
                    name="email"
                    id="email"
                    placeholder="Email"
                    required
                  />
                </div>
                <div className="w-1/2">
                  <p className="text-gray-500">Rôle :</p>
                  <select
                    className="w-full border-b-[1px] border-nxm-darkGray h-10 bg-transparent"
                    onChange={(e) => setSelectedRole(parseInt(e.target.value))}
                    required
                  >
                    <option value="">Selectionner un rôle</option>
                    {roles.map((role) => (
                      <option key={role.value} value={role.value}>
                        {role.label}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
            </div>
          </div>
          {/*footer*/}
          <div className="flex items-center justify-end 2xl:p-4 p-2 border-t border-solid border-slate-200 rounded-b bg-white font-BarlowCondensed">
            <button
              className="text-red-300 background-transparent hover:text-red-500 font-bold uppercase px-6 py-2 2xl:text-lg text-base  outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
              type="button"
              onClick={() => closeModal()}
            >
              Annuler
            </button>
            <button
              className="text-white bg-gradient-to-r from-light-lavender via-light-lavender to-dark-lavender hover:bg-gradient-to-br font-medium rounded-lg 2xl:text-lg text-base  px-5 py-2.5 text-center me-2 uppercase  shadow hover:shadow-lg outline-none  ease-linear transition-all duration-150"
              type="button"
              onClick={() => {
                handleCreateUser();
              }}
            >
              Créer
            </button>
          </div>
        </div>
      </div>
      <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
    </>
  );
};

export default AddUser;
