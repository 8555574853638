import { ToastContainer } from "react-toastify";
import Header from "../../components/Layout/Header";
import SideBar from "../../components/Layout/SideBar";
import Footer from "../../components/Layout/Footer";
import { useEffect } from "react";
import { useProfil } from "../../stores/Layout/profil";
import { getUserInformation } from "../../services/Api/loginService";
import { updateProfil } from "../../services/Api/userService";
import { showToastSuccess } from "../../services/Toasts/toasts";
import useModal from "../../hooks/useModal";
import DeleteProfil from "../../components/Layout/DeleteProfil";

const Profil = () => {
  const {
    firstName,
    setFirstName,
    lastName,
    setLastName,
    email,
    setEmail,
    phone,
    setPhone,
    lastPassword,
    setLastPassword,
    newPassword,
    setNewPassword,
    reset,
  } = useProfil();

  // Hook
  const { modalContent, isModalOpen, openModal, closeModal } = useModal();


  useEffect(() => {
    getUserInformation()
      .then((res) => {
        setFirstName(res.data.firstname);
        setLastName(res.data.lastname);
        setEmail(res.data.eMail);
        setPhone(res.data.tel);
      })
      .catch((err) => console.log(err));
  }, [reset]);

  const handleUpdateProfil = () => {
    const data = {
      lastName,
      firstName,
      email,
      lastPassword,
      newPassword,
      tel: phone,
    };

    updateProfil(data)
      .then(() => {
        showToastSuccess("Votre profil à bien été modifié");
        setNewPassword("");
        setLastPassword("");
        reset();
      })
      .catch((err) => console.log(err));
  };

  return (
    <div className="w-screen h-screen bg-white flex flex-col justify-start items-start overflow-hidden">
      <Header />
      <div className="w-full flex justify-start items-start">
        <SideBar />
        <ToastContainer position="top-center" />
        {isModalOpen ? modalContent : null}
        <div className="flex flex-col justify-start items-center w-11/12 h-screen m-auto">
          <div className="w-11/12 flex justify-start sm:justify-between items-center 3xl:my-5 xl:my-3">
            <h2 className="my-2 text-nxm-darkGray font-light 2xl:text-3xl text-xl mr-5 ml-1 font-K2D ">
              Votre profil :
            </h2>
          </div>
          <div className="hidden md:flex flex-col justify-around items-center w-9/12 my-10 m-auto">
            <p className="text-grey-trio mb-10 text-start w-full pl-4">
              Vous pouvez mettre à jour vos informations personnels
            </p>
            <div className="flex justify-center items-center gap-5 w-full mt-10">
              <div className="flex flex-col justify-start items-start w-1/2 pl-4">
                <p>Prénom :</p>
                <input
                  type="text"
                  value={firstName}
                  onChange={() => {}}
                  className="border-b-[1px] w-1/2 mb-5 h-10 rounded-md bg-white focus:outline-none mt-1 shadow-xl pl-1"
                />
              </div>
              <div className="flex flex-col justify-start items-start w-1/2">
                <p>Nom :</p>
                <input
                  type="text"
                  value={lastName}
                  className="border-b-[1px] w-1/2 mb-5 h-10 rounded-md bg-white focus:outline-none mt-1 shadow-xl pl-1"
                />
              </div>
            </div>
            <div className="flex justify-around items-center gap-5 w-full">
              <div className="flex flex-col justify-start items-start w-1/2 pl-4">
                <p>Email :</p>
                <input
                  type="text"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  className="border-b-[1px] w-1/2 mb-5 h-10 rounded-md bg-white focus:outline-none mt-1 shadow-xl pl-1"
                />
              </div>
              <div className="flex flex-col justify-start items-start w-1/2">
                <p>Phone :</p>
                <input
                  type="text"
                  value={phone}
                  onChange={(e) => setPhone(e.target.value)}
                  className="border-b-[1px] w-1/2 mb-5 h-10 rounded-md bg-white focus:outline-none mt-1 shadow-xl pl-1"
                />
              </div>
            </div>
            <div className="flex justify-around items-center gap-5 w-full bg-light-lavender bg-opacity-20 p-4 border-[1px] rounded-md">
              <div className="flex flex-col justify-start items-start w-1/2">
                <p>Ancien mot de passe :</p>
                <input
                  type="password"
                  value={lastPassword}
                  onChange={(e) => setLastPassword(e.target.value)}
                  className="border-b-[1px] w-1/2 mb-5 h-10 rounded-md bg-white focus:outline-none mt-1 shadow-xl pl-1"
                />
              </div>
              <div className="flex flex-col justify-start items-start w-1/2">
                <p>Définir votre nouveau mot de passe :</p>
                <input
                  type="password"
                  value={newPassword}
                  onChange={(e) => setNewPassword(e.target.value)}
                  className="border-b-[1px] w-1/2 mb-5 h-10 rounded-md bg-white focus:outline-none mt-1 shadow-xl pl-1"
                />
              </div>
            </div>
            <div className="w-full flex items-center justify-end mt-10">
              <button className="text-red-300 background-transparent hover:text-red-500 font-bold uppercase px-6 py-2 2xl:text-lg text-base  outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
              onClick={() => openModal(<DeleteProfil closeModal={closeModal}/>)}
              >
                Supprimer votre compte
              </button>
              <button
                className="text-white bg-gradient-to-r from-light-lavender via-light-lavender to-dark-lavender hover:bg-gradient-to-br font-medium rounded-lg 2xl:text-lg text-base  px-5 py-2.5 text-center  uppercase  shadow hover:shadow-lg outline-none ease-linear transition-all duration-150"
                type="button"
                onClick={() => handleUpdateProfil()}
              >
                Modifier
              </button>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Profil;
