import logo from "../../assets/logos/logoX.png";
import user from "../../assets/icons/user.svg";
import deco from "../../assets/icons/logout.svg";
import { useTokenContext } from "../../context/tokenContext";
import useModal from "../../hooks/useModal";
import ModalDeconnexion from "./ModalDeconnexion";
import { useNavigate } from "react-router-dom";

const Header = () => {
  const { firstname, lastname } = useTokenContext();
  const { modalContent, isModalOpen, openModal, closeModal } = useModal();
  const navigate = useNavigate();

  return (
    <div className="w-screen h-14 bg-dark-lavender flex justify-between items-center z-50 p-2">
      {isModalOpen ? modalContent : null}
      <img src={logo} alt="logo Nexum" className="h-8 w-6 ml-2" />
      <div className="flex justify-start items-center">
        <button
          className="flex justify-start items-center mr-20 border-[1px] rounded-md bg-white px-4 py-1 shadow-xl"
          onClick={() => navigate("/profil")}
        >
          <img src={user} alt="icon user" className="h-6 w-6 mr-2" />
          <p className="text-dark-lavender font-K2D">
            {firstname} - {lastname}
          </p>
        </button>
        <button
          className=""
          onClick={() =>
            openModal(<ModalDeconnexion closeModal={closeModal} />)
          }
        >
          <img src={deco} alt="icon déconnexion" className="h-6 w-6 mr-10" />
        </button>
      </div>
    </div>
  );
};

export default Header;
